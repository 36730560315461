"use client";

import { Dialog } from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";

import CreateEditFeedDialog from "./CreateEditFeedDialog";
import events from "@/app/utils/events";
import {
  FORCE_REFRESH_FEED,
  TRIGGER_FEED_FILTERS_MODAL,
} from "@/app/eventConstants";
import { FeedFilter } from "@/app/types/FeedFitler.type";
import useFeeds from "@/app/hooks/useFeeds";
import { useAppStore } from "@/app/store";
import useShallowNavigation from "@/app/hooks/useShallowNavigation";
import { CreateFeedPayload, StructFeed } from "@/app/types/Feed.type";

interface LocalState {
  filters: FeedFilter[];
  feedId?: StructFeed["id"];
  name?: StructFeed["name"];
  emoji?: string;
  bits?: CreateFeedPayload["bits"];
  entityMap: StructFeed["entity_map"];
}

const INITIAL_STATE = {
  feedId: "",
  filters: [],
  name: "",
  emoji: "",
  entityMap: {},
};

const CreateFeedDialog = () => {
  const { createFeed, updateFeed } = useFeeds();

  const [isFeedFormOpen, setIsFeedFormOpen] = useState(false);

  const localState = useRef<LocalState>(INITIAL_STATE);
  const currentFeedId = useAppStore((state) => state.currentFeedId);
  const setCurrentFeedId = useAppStore((state) => state.setCurrentFeedId);
  const { currentPath: threadId } = useShallowNavigation();

  const openModal = ({
    filters = [],
    feedId = "",
    name = "",
    emoji = "",
    bits = {},
    entityMap = {},
  }: LocalState) => {
    if (filters) {
      localState.current = { feedId, filters, name, emoji, bits, entityMap };
    }
    setIsFeedFormOpen(true);
  };

  useEffect(() => {
    // todo: fix types below
    // @ts-ignore
    events.on(TRIGGER_FEED_FILTERS_MODAL, openModal);
    // @ts-ignore
    return () => events.off(TRIGGER_FEED_FILTERS_MODAL, openModal);
  }, []);

  const handleBack = () => {
    setIsFeedFormOpen(false);
    localState.current = INITIAL_STATE;
  };

  const handleSubmit = async ({
    feedName,
    emoji,
    filters,
    bits,
  }: {
    feedName: StructFeed["name"];
    emoji: string;
    filters: FeedFilter[];
    bits: CreateFeedPayload["bits"];
  }) => {
    const feedId = localState.current.feedId;
    const payload = {
      name: feedName,
      filters,
      emoji,
      bits,
    };

    if (feedId) {
      // update feed
      await updateFeed({ ...payload, id: feedId });

      if (!threadId && feedId === currentFeedId) {
        events.emit(FORCE_REFRESH_FEED, { currentFeedId });
      }
      setIsFeedFormOpen(false);
      return;
    }

    // create feed
    const response = await createFeed(payload);
    if (!threadId) {
      setCurrentFeedId(response.id);
    }

    localState.current = INITIAL_STATE;
    setIsFeedFormOpen(false);
  };

  return (
    <Dialog.Root open={isFeedFormOpen} onOpenChange={setIsFeedFormOpen}>
      <Dialog.Content>
        <CreateEditFeedDialog
          handleClose={handleBack}
          onSubmit={handleSubmit}
          localFilters={localState.current.filters}
          feedName={localState.current.name}
          emoji={localState.current.emoji}
          bits={localState.current.bits}
          entityMap={localState.current.entityMap}
        />
      </Dialog.Content>
    </Dialog.Root>
  );
};
export default CreateFeedDialog;
