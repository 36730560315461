import React from "react";
import { Avatar, Button, Dialog, Flex, Text } from "@radix-ui/themes";
import { ArrowLeftIcon } from "@radix-ui/react-icons";

import { getAvatarImageURL } from "@/app/utils/imageUtils";

interface AvatarVariantSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  avatarVariantList: string[];
  onAvatarSelect: (avatarName: string) => void;
}

export const AvatarVariantSelector = ({
  isOpen,
  onClose,
  avatarVariantList,
  onAvatarSelect,
}: AvatarVariantSelectorProps) => {
  if (avatarVariantList.length === 0) {
    return null;
  }

  const selectedAvatarPrefix = avatarVariantList[0].split("-")[0];

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Content size="4">
        <Dialog.Title size="6">
          Good pick! Which is your favorite {selectedAvatarPrefix}?
        </Dialog.Title>

        <Button size="2" variant="soft" color="gray" mb="4" onClick={onClose}>
          <ArrowLeftIcon height="16" width="16" />
          <Text size="2">Start over</Text>
        </Button>

        <Flex direction={"row"} wrap={"wrap"} align="center" gap="4">
          {avatarVariantList.map((avatar) => (
            <Button
              style={{
                width: "var(--space-9)",
              }}
              variant="ghost"
              key={avatar}
            >
              <Flex direction={"column"} gap="1" align="center">
                <Avatar
                  size="5"
                  src={getAvatarImageURL(`anon-${avatar}`)}
                  fallback={avatar}
                  radius="large"
                  onClick={() => onAvatarSelect(avatar)}
                />
              </Flex>
            </Button>
          ))}
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray" onClick={onClose}>
              Cancel
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};
