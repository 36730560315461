import { StructOrganisation } from "@/app/types/Organisation.type";
import { structAPIFetchClient } from "./client";
import { STRUCT_API_VERSION } from "@/app/constants";

const ORGANISATION_ENDPOINT = `/${STRUCT_API_VERSION}/org`;
const INVITE_SECRET_ENDPOINT = `/${STRUCT_API_VERSION}/join-secret`;

export const getOrgDetails = () => {
  return structAPIFetchClient(ORGANISATION_ENDPOINT, {
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      return data as StructOrganisation;
    });
};

type SwitchOrganisationResponse = {
  ok: boolean;
};

export const switchOrganisation = ({
  organisationId,
}: {
  organisationId?: StructOrganisation["id"];
}) => {
  return structAPIFetchClient(`/switch/${organisationId}`, {
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      return data as SwitchOrganisationResponse;
    });
};

export const updateOrgDetails = ({
  hostname = undefined,
  name = undefined,
  title = undefined,
  description = undefined,
  cta_url = undefined,
  ga_id = undefined,
  javascript = undefined,
  avatar_id = undefined,
}: {
  hostname?: string;
  name?: string;
  title?: string;
  description?: string;
  cta_url?: string;
  ga_id?: string;
  javascript?: string;
  avatar_id?: string;
}) => {
  return structAPIFetchClient(ORGANISATION_ENDPOINT, {
    method: "PUT",
    body: JSON.stringify({
      hostname,
      name,
      title,
      description,
      cta_url,
      ga_id,
      javascript,
      avatar_id,
    }),
  });
};

export const updateInviteSecretAPI = () =>
  structAPIFetchClient(INVITE_SECRET_ENDPOINT, {
    method: "PUT",
    body: JSON.stringify({}),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(({ data }) => data as StructOrganisation);
