import { Flex, Text } from "@radix-ui/themes";
import { PlayIcon } from "@radix-ui/react-icons";

const VIDEO_TUTORIAL_URL = "https://struct.ai/demo-video";

const VideoTutorialButton = () => (
  <a
    href={VIDEO_TUTORIAL_URL}
    style={{ textDecoration: "none" }}
    target="_blank"
  >
    <Flex direction="row" py="4" gap="1" mr="auto">
      <Flex mt="1">
        <PlayIcon />
      </Flex>
      <Flex direction="column" gap="1">
        <Flex align="center" gap="1" style={{ color: "var(--slate-12)" }}>
          <Text size="2">Watch demo</Text>
        </Flex>
        <Text
          size="1"
          style={{
            color: "var(--indigo-10)",
            fontSize: "10px",
          }}
        >
          New to Struct? Start here.
        </Text>
      </Flex>
    </Flex>
  </a>
);

export default VideoTutorialButton;
