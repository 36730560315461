import React from "react";
import { Command } from "cmdk";
import styles from "./CommandMenuItem.module.scss";
import KeyboardShortcut from "@/app/components/KeyboardShortcut";
import Tag from "@/app/components/Tag";

interface CommandMenuItemProps {
  value: string;
  onSelect: () => void;
  icon?: React.ElementType;
  shortcut?: Array<string>;
  isChecked?: boolean;
  hasCheckbox?: boolean;
  tagValue?: number | string;
}

const CommandMenuItem = ({
  value,
  onSelect,
  icon,
  shortcut,
  isChecked,
  hasCheckbox = false,
  tagValue,
}: CommandMenuItemProps) => {
  const Icon = icon;
  return (
    <Command.Item
      value={value} // unique value expected
      className={styles.commandMenuItem}
      onSelect={onSelect}
    >
      {hasCheckbox && (
        <input
          className={styles.itemCheckbox}
          checked={isChecked}
          onChange={() => {}}
          type="checkbox"
        />
      )}
      {Icon && <Icon className={styles.itemIcon} />}
      {value}
      {tagValue !== undefined && (
        <Tag size="small" label={tagValue} className={styles.count} />
      )}
      {shortcut?.length && (
        <div className={styles.kbdContainer}>
          {shortcut?.map((shortcut) => (
            <KeyboardShortcut size="small" key={shortcut} label={shortcut} />
          ))}
        </div>
      )}
    </Command.Item>
  );
};

export default CommandMenuItem;
