import { useCallback } from "react";
import { uploadImageAPI } from "@/app/services/image";
import { useAppStore } from "../store";

const useFiles = () => {
  const organisation = useAppStore((state) => state.organisation);

  const { id: organisationId } = organisation;

  const uploadFile = useCallback(
    (file: File) =>
      uploadImageAPI(file).catch((error) => {
        console.log(error);
      }),
    [organisationId],
  );

  return {
    uploadFile,
  };
};

export default useFiles;
