import isEmpty from "lodash.isempty";
import { useAppStore } from "@/app/store";
import { Text, Flex, Avatar } from "@radix-ui/themes";
import { StructOrganisation } from "@/app/types/Organisation.type";
import { getAvatarImageURL } from "@/app/utils/imageUtils";
import styles from "./OrgSwitcher.module.scss";
import { ArrowRightIcon } from "@radix-ui/react-icons";
import useOrganisation from "@/app/hooks/useOrganisation";

const OrgSwitcher = () => {
  const session = useAppStore((state) => state.session);
  const { handleSwitchOrganisation } = useOrganisation();

  // Don't show org switcher if user isn't part of multiple
  // organizations or is part of only one.
  if (isEmpty(session?.orgs) || session?.orgs.length === 1) {
    return null;
  }

  const handleSwitchOrganization = async (
    organization: StructOrganisation,
  ): Promise<void> => {
    await handleSwitchOrganisation(organization);
  };

  const currentOrganisationId = session?.user?.org_id;

  return (
    <div>
      <Text size="2" my="1" style={{ color: "var(--olive-10)" }}>
        Switch Organizations
      </Text>
      <Flex
        direction="column"
        style={{
          maxHeight: "calc(100vh - 15rem)",
          overflowY: "auto",
        }}
      >
        {session?.orgs
          .filter((org) => org.id !== currentOrganisationId)
          .map((org) => (
            <div
              key={org.id}
              onClick={() => handleSwitchOrganization(org)}
              className={styles.orgItem}
            >
              <Flex gap="3" align="center">
                <Avatar
                  src={getAvatarImageURL(org?.avatar_id as string, 128)}
                  fallback={org?.name?.[0] as string}
                  size="2"
                  alt={org?.name}
                />
                <Flex justify="between" align="center" width="100%" pr="2">
                  <Text>{org.name}</Text>
                  <ArrowRightIcon className={styles.icon} />
                </Flex>
              </Flex>
            </div>
          ))}
      </Flex>
    </div>
  );
};

export default OrgSwitcher;
