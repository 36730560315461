import { Flex, Popover, Button, Avatar, Text } from "@radix-ui/themes";
import React, { useState } from "react";
import OrgSwitcher from "./OrgSwitcher";
import { getAvatarImageURL } from "@/app/utils/imageUtils";
import { useAppStore } from "@/app/store";

import {
  DotsVerticalIcon,
  InfoCircledIcon,
  MixerHorizontalIcon,
} from "@radix-ui/react-icons";
import OrgInfoDialog from "./OrgInfo";
import styles from "./OrgSwitcher.module.scss";
import RealtimeIndicator from "./RealtimeIndicator";
import { isApp } from "@/app/constants";

const OrgHeader = () => {
  const organisation = useAppStore((state) => state.organisation);
  const isAuthenticatedWithCorrectOrg = useAppStore(
    (state) => state.isAuthenticatedWithCorrectOrg,
  );

  return (
    <Flex
      align="center"
      style={{
        paddingTop: "var(--space-3)",
        paddingBottom: "var(--space-3)",
        userSelect: "none",
      }}
    >
      <Avatar
        src={getAvatarImageURL(organisation?.avatar_id, 128)}
        fallback={organisation?.name?.[0]}
        size="2"
        ml="2"
        alt={organisation?.name}
      />
      <Flex direction="column" ml="3">
        <Text
          size="3"
          weight="medium"
          style={{
            color: "var(--slate-12)",
          }}
        >
          {organisation?.name}
        </Text>
        {isAuthenticatedWithCorrectOrg && <RealtimeIndicator />}
      </Flex>
    </Flex>
  );
};

const Header = () => {
  const session = useAppStore((state) => state.session);
  const organisation = useAppStore((state) => state.organisation);
  const [orgInfoDialogOpen, setOrgInfoDialogOpen] = useState(false);
  const hasAccessToMultipleOrganisations = (session?.orgs?.length ?? 0) > 1;

  return (
    <>
      <Popover.Root>
        <Popover.Trigger>
          <Button
            variant="ghost"
            style={{
              display: "flex",
              width: "95%",
              justifyContent: "flex-start",
            }}
          >
            <OrgHeader />
            <Flex align="center" justify="end" grow="1">
              <DotsVerticalIcon />
            </Flex>
          </Button>
        </Popover.Trigger>

        <Popover.Content style={{ width: 160 }}>
          <Flex direction="column" gap="3">
            <Flex
              style={{
                ...(hasAccessToMultipleOrganisations
                  ? {
                      borderBottom: "1px solid var(--gray-2)",
                      paddingBottom: 10,
                    }
                  : {}),
                flex: 1,
              }}
            >
              <Flex
                gap="3"
                align="center"
                onClick={() => setOrgInfoDialogOpen(true)}
                className={styles.orgItem}
                style={{ flex: 1 }}
              >
                <Flex
                  justify="center"
                  align="center"
                  p="2"
                  style={{
                    background: "var(--olive-4)",
                    borderRadius: "var(--radius-2)",
                  }}
                >
                  {session?.isOwner || session?.isAdmin ? (
                    <MixerHorizontalIcon />
                  ) : (
                    <InfoCircledIcon />
                  )}
                </Flex>
                <Flex justify="between" align="center" width="100%" pr="2">
                  <Text style={{ color: "var(--olive-10)" }} size="2">
                    {session?.isOwner || session?.isAdmin
                      ? `Edit ${organisation.name} Settings`
                      : `About ${organisation.name}`}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            {hasAccessToMultipleOrganisations && isApp && <OrgSwitcher />}
          </Flex>
        </Popover.Content>
      </Popover.Root>

      {orgInfoDialogOpen && (
        <OrgInfoDialog onClose={() => setOrgInfoDialogOpen(false)} />
      )}
    </>
  );
};

export default Header;
