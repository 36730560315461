import React, { useState, Dispatch, SetStateAction } from "react";
import { useTheme } from "next-themes";
import {
  Dialog,
  Button,
  Flex,
  TextField,
  Text,
  Popover,
  IconButton,
} from "@radix-ui/themes";
import Picker from "@emoji-mart/react";
import { FaceIcon, Pencil2Icon, PlusIcon } from "@radix-ui/react-icons";
import styles from "./CreateFeed.module.scss";
import emojiStyles from "@/app/styles/emoji.module.scss";

interface NameEmojiStepProps {
  name?: string;
  emoji?: string;
  setFeedName: Dispatch<SetStateAction<string>>;
  setSelectedEmoji: Dispatch<SetStateAction<string>>;
}

const NameEmojiStep = ({
  name: feedName,
  emoji: selectedEmoji,
  setFeedName,
  setSelectedEmoji,
}: NameEmojiStepProps) => {
  const { theme } = useTheme();

  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  const handleEmojiSelect = ({ native }: { native: string }) => {
    setIsEmojiPickerOpen(false);
    setSelectedEmoji(native);
  };

  return (
    <>
      <Dialog.Title>Custom feed</Dialog.Title>
      <Dialog.Description size="2" mb="4">
        Custom feeds let you add and edit search terms to tailor content to your
        preferences.
      </Dialog.Description>
      <Flex align="end" width="100%" justify="between" gap="2">
        <Text
          as="label"
          weight="medium"
          size="2"
          style={{
            flex: 1,
          }}
        >
          Name
          <TextField.Root size="3" variant="surface">
            <TextField.Input
              value={feedName}
              placeholder="Enter a name"
              onChange={(ev) => setFeedName(ev?.target?.value)}
              required
            />
            <TextField.Slot>
              <Pencil2Icon
                height="16"
                width="16"
                style={{
                  color: "var(--indigo-9)",
                }}
              />
            </TextField.Slot>
          </TextField.Root>
        </Text>
        <Flex
          direction="row"
          gap="0"
          align="end"
          style={{
            position: "relative",
          }}
        >
          <Flex
            align="center"
            justify="center"
            className={styles.emojiBox}
            onClick={() => setIsEmojiPickerOpen(true)}
          >
            {selectedEmoji ? (
              <Text size="4" className={emojiStyles.notoEmoji}>
                {selectedEmoji}
              </Text>
            ) : (
              <FaceIcon height="24" width="24" />
            )}
          </Flex>
          <Popover.Root open={isEmojiPickerOpen}>
            <Popover.Trigger>
              <IconButton
                onClick={() => setIsEmojiPickerOpen(true)}
                style={{
                  position: "absolute",
                  right: "calc(var(--space-2) * -1)",
                  bottom: "calc(var(--space-2) * -1)",
                }}
                size="1"
              >
                <PlusIcon />
              </IconButton>
            </Popover.Trigger>
            <Popover.Content>
              <Picker theme={theme} onEmojiSelect={handleEmojiSelect} />
            </Popover.Content>
          </Popover.Root>
        </Flex>
      </Flex>
    </>
  );
};

export default NameEmojiStep;
