"use client";
import React, { ReactNode, useState } from "react";
import useDesktopMode from "../hooks/useDesktopMode";

const THRESHOLD = 5;

const MobileViewContainer = ({ children }: { children: ReactNode }) => {
  const [clickCounter, setClickCounter] = useState(0);
  const { isDesktop } = useDesktopMode();
  if (!isDesktop && clickCounter < THRESHOLD) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 onClick={() => setClickCounter((ctr) => ctr + 1)}>
          Struct is only available on Desktop.
        </h1>

        <h3>Mobile view is coming soon</h3>
      </div>
    );
  }
  return children;
};

export default MobileViewContainer;
