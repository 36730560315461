"use client";
import React, { useEffect, useRef, ReactNode } from "react";
import { useApp } from "@/app/hooks/useApp";
import useShallowNavigation from "@/app/hooks/useShallowNavigation";
import throttle from "lodash/throttle";
import { useAppStore } from "../store";
import { getLocalStorageState } from "../utils/localStorage";
import events from "../utils/events";
import { TRIGGER_NOTIFICATION } from "../eventConstants";

const NOTIFICATION_AUDIO_URL =
  // "https://f004.backblazeb2.com/file/struct-pub00/struct-notification.mp3";
  "https://f004.backblazeb2.com/file/struct-pub00/struct-notification2.wav";

export const NOTIFICATION_RATE_LIMIT = 15 * 1000; // 60 seconds

interface NotificationProps {
  children: ReactNode;
}

const NotificationContainer: React.FC<NotificationProps> = ({ children }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const { featureToggles } = useApp();
  const { getCurrentPath, navigateToPath } = useShallowNavigation();
  const isAuthenticatedWithCorrectOrg = useAppStore(
    (state) => state.isAuthenticatedWithCorrectOrg,
  );

  const isNotificationSupported =
    typeof window !== "undefined" && "Notification" in window;

  useEffect(() => {
    const isNotificationEnabled =
      isAuthenticatedWithCorrectOrg &&
      featureToggles.isChatEnabled &&
      isNotificationSupported;

    if (!isNotificationEnabled) {
      return;
    }

    Notification.requestPermission();
  }, [
    isNotificationSupported,
    featureToggles.isChatEnabled,
    isAuthenticatedWithCorrectOrg,
  ]);

  const playAudio = () => {
    audioRef.current?.play();
  };

  const throttledPlayAudio = throttle(playAudio, NOTIFICATION_RATE_LIMIT, {
    leading: true,
    trailing: false,
  });

  const triggerNotification = async ({
    title,
    options,
    threadId,
    isMentioned,
  }: {
    title: string;
    options: NotificationOptions;
    threadId: string;
    isMentioned: boolean;
  }) => {
    console.log("notify", {
      title,
      options,
      threadId,
      isMentioned,
    });
    const { isNotificationEnabled = true } = getLocalStorageState();
    if (!isNotificationEnabled) return;

    const currentlyOpenThreadId = await getCurrentPath();
    const isAppHidden = window?.document.hidden;
    const isAppFocused = window?.document.hasFocus();
    const isIncomingThreadOpen = currentlyOpenThreadId
      ? threadId === currentlyOpenThreadId
      : true;
    const isNotificationPermissionGranted =
      Notification.permission === "granted";

    const shouldShowNotification =
      isNotificationPermissionGranted &&
      isNotificationSupported &&
      (!isAppFocused || isAppHidden || isMentioned || !isIncomingThreadOpen);

    if (!shouldShowNotification) {
      return;
    }

    const notification = new Notification(`[Struct] ${title}`, options);
    throttledPlayAudio();

    if (!notification) return;

    notification.addEventListener("click", () => {
      navigateToPath(threadId);
      window.focus();
    });
  };

  useEffect(() => {
    events.on(TRIGGER_NOTIFICATION, triggerNotification);
    return () => {
      events.off(TRIGGER_NOTIFICATION, triggerNotification);
    };
  }, []);

  return (
    <React.Fragment>
      <audio ref={audioRef} src={NOTIFICATION_AUDIO_URL} preload="auto" />
      {children}
    </React.Fragment>
  );
};

export default NotificationContainer;
