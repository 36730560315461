import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Dialog,
  Flex,
  IconButton,
  Text,
  TextField,
} from "@radix-ui/themes";
import { Cross1Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons";

import { getAvatarListAPI } from "@/app/services/users";
import { getAvatarImageURL } from "@/app/utils/imageUtils";
import { capitalize } from "@/app/utils/stringUtils";
import { AvatarVariantSelector } from "./AvatarVariantSelector";
import { AnimatePresence, motion } from "framer-motion";

interface AvatarSelectorProps {
  isOpen: boolean;
  onClose: () => void;
  onAvatarSelect: (avatarName: string) => void;
}

export const AvatarSelector = ({
  isOpen,
  onClose,
  onAvatarSelect,
}: AvatarSelectorProps) => {
  const [avatarList, setAvatarList] = useState<{ [key: string]: string[] }>({});
  const [selectedAvatar, setSelectedAvatar] = useState<string>();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const getAvatarList = async () => {
    try {
      const avatarListResponse = await getAvatarListAPI();
      setAvatarList(avatarListResponse.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAvatarList();
    return () => {
      setSelectedAvatar(undefined);
    };
  }, []);

  return (
    <>
      {selectedAvatar && (
        <AvatarVariantSelector
          isOpen={!!selectedAvatar}
          onClose={() => setSelectedAvatar(undefined)}
          avatarVariantList={avatarList[selectedAvatar]}
          onAvatarSelect={onAvatarSelect}
        />
      )}
      <Dialog.Root open={isOpen}>
        <Dialog.Content
          style={{ maxWidth: 790, height: 800, overflow: "hidden" }}
          size="4"
        >
          <Flex direction="row" justify="between">
            <Dialog.Title size="6">Select an Avatar</Dialog.Title>
            <IconButton variant="ghost" onClick={onClose}>
              <Cross1Icon />
            </IconButton>
          </Flex>

          <Dialog.Description>
            Our avatars are inspired by famous people from history.
          </Dialog.Description>

          <Flex direction="row" gap="2" mt="4" mb="6" align="center">
            <Text
              size="1"
              style={{
                color: "var(--slate-11)",
              }}
            >
              Looking for someone?
            </Text>
            <TextField.Root>
              <TextField.Slot>
                <MagnifyingGlassIcon height="16" width="16" />
              </TextField.Slot>
              <TextField.Input
                size="1"
                value={searchQuery}
                placeholder="Search"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchQuery(event.target.value);
                }}
              />
            </TextField.Root>
          </Flex>

          <Flex
            direction="row"
            wrap="wrap"
            align="center"
            gap="4"
            p="2"
            style={{
              overflowY: "auto",
              maxHeight: 600,
            }}
          >
            <AnimatePresence mode="popLayout">
              {Object.keys(avatarList)
                .filter((avatar) => avatar.includes(searchQuery))
                .map((avatar) => {
                  const avatarFirstName = capitalize(avatar.split("-")[0]);
                  return (
                    <motion.div
                      key={avatar}
                      layout="preserve-aspect"
                      initial={{
                        opacity: 0,
                      }}
                      animate={{
                        opacity: 1,
                      }}
                      exit={{
                        opacity: 0,
                      }}
                    >
                      <Button
                        style={{
                          width: "var(--space-9)",
                        }}
                        key={avatar}
                        variant="ghost"
                      >
                        <Flex
                          direction="column"
                          gap="1"
                          style={{
                            width: "100%",
                          }}
                        >
                          <Avatar
                            size="5"
                            src={getAvatarImageURL(`anon-${avatar}`)}
                            fallback={avatar}
                            radius="large"
                            onClick={() => setSelectedAvatar(avatar)}
                          />
                          <Text
                            align="center"
                            size="1"
                            style={{
                              color: "var(--slate-11)",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {avatarFirstName}
                          </Text>
                        </Flex>
                      </Button>
                    </motion.div>
                  );
                })}
            </AnimatePresence>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
