import styles from "./Spinner.module.scss";

const Spinner = ({
  className = styles.default,
  style,
}: {
  className?: string;
  style?: React.CSSProperties;
}) => (
  <span style={style} className={[styles.loader, className].join(" ")}></span>
);
export default Spinner;
