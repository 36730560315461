"use client";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Flex, Text, Theme } from "@radix-ui/themes";
import { Command } from "cmdk";

import CommandMenuItem from "@/app/components/CommandMenu/CommandMenuItem";
import NoResultsItem from "@/app/components/CommandMenu/NoResultsItem";
import KeyboardShortcut from "@/app/components/KeyboardShortcut";
import Spinner from "@/app/components/Spinner";
import useShallowNavigation from "@/app/hooks/useShallowNavigation";
import { useAppStore } from "@/app/store";
import { HomeIcon } from "@radix-ui/react-icons";
import styles from "./CommandMenu.module.scss";

import SortingFilter from "./SortingFilter";

import BasicEditor from "../ChatBox/BasicEditor";
import { processSearchQuery } from "@/app/utils/stringUtils";
import { SortingOption } from "@/app/types/Sorting.type";
import { useAllFilters } from "@/app/hooks/useAllFilters";
import FeedItemFooter from "../FeedItem/FeedItemFooter";
import { formatTimeAgo } from "@/app/utils/timeUtils";
import { DateTime } from "luxon";
import useSearch from "@/app/hooks/useSearch";
import events from "@/app/utils/events";
import { EMBED_THREAD_FROM_CMDK } from "@/app/eventConstants";
import InsertThreadLinkIcon from "../icons/InsertThreadLinkIcon";

const SELECT_EVENT = `cmdk-item-select`;
const ITEM_SELECTOR = `[cmdk-item=""]`;
export const SEPARATOR = "-|-|-";

const CommandMenu = () => {
  const filters = useAppStore((state) => state.filters);
  const setSearchQuery = useAppStore((state) => state.setSearchQuery);
  const searchQuery = useAppStore((state) => state.searchQuery);

  const setFiltersByType = useAppStore((state) => state.setFiltersByType);
  const sorting = useAppStore((state) => state.sorting);
  const setSorting = useAppStore((state) => state.setSorting);

  const stateRef = useRef({
    filters,
    searchQuery,
    sorting,
    userInput: searchQuery || "",
    isLoaded: false,
  });

  const isCommandMenuVisible = useAppStore(
    (state) => state.isCommandMenuVisible,
  );
  const isCommandMenuVisibleViaMentionPanel = useAppStore(
    (state) => state.isCommandMenuVisibleViaMentionPanel,
  );
  const setIsCommandMenuVisible = useAppStore(
    (state) => state.setIsCommandMenuVisible,
  );

  const chatBoxCommands = useAppStore((state) => state.chatBoxCommands);

  const { navigateToPath } = useShallowNavigation();

  const { areFiltersApplied } = useAllFilters();

  const {
    debouncedSearch,
    isSearching,
    results: searchResults,
    setResults: setSearchResults,
    searchQuery: formattedSearchQuery,
  } = useSearch();

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setIsCommandMenuVisible(!isCommandMenuVisible);
      }
    };

    document.addEventListener("keydown", down);
    return () => {
      document.removeEventListener("keydown", down);
    };
  }, []);

  const handleSearch = (input: string) => {
    debouncedSearch.cancel();

    const [channels, users, tags, inputSearchQuery] = processSearchQuery(input);
    stateRef.current = {
      filters: { channels, users, tags },
      searchQuery: inputSearchQuery,
      sorting: stateRef.current.sorting,
      userInput: input,
      isLoaded: true,
    };

    debouncedSearch(input, stateRef.current.sorting);
  };

  const handleChangeSort = (sorting: SortingOption) => {
    stateRef.current.sorting = sorting;
    handleSearch(stateRef.current.userInput);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isInputOrTextarea = ["INPUT", "TEXTAREA", "DIV"].includes(
        (event.target as HTMLElement).tagName,
      );

      if (isInputOrTextarea) {
        return;
      }

      if (event.key === "/") {
        event.stopPropagation();
        event.preventDefault();
        setIsCommandMenuVisible(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setIsCommandMenuVisible]);

  useEffect(() => {
    if (!areFiltersApplied) {
      stateRef.current.isLoaded = false;
      stateRef.current.userInput = "";
      resetSearchResults();
    }
  }, [areFiltersApplied]);

  useEffect(() => {
    if (isCommandMenuVisibleViaMentionPanel) {
      stateRef.current.isLoaded = false;
      stateRef.current.userInput = "";
      setSearchResults([...[]]);
    }
  }, [isCommandMenuVisibleViaMentionPanel]);

  const handleCloseDialog = () => {
    resetSearchResults();
    stateRef.current.isLoaded = false;
    stateRef.current.userInput = "";
    if (isCommandMenuVisibleViaMentionPanel) {
      chatBoxCommands?.focus();
    }
    setIsCommandMenuVisible(false);
  };

  const handleOpenThread = (threadId: string) => {
    if (isCommandMenuVisibleViaMentionPanel) {
      handleThreadEmbed(threadId.toLowerCase());
      return;
    }
    navigateToPath(threadId);
    handleCloseDialog();
  };

  const handleThreadEmbed = (threadId: string) => {
    const { id, subject } = searchResults.find(
      (item) => item.id.toLowerCase() === threadId,
    ) ?? { id: "", subject: "" };

    if (!id || !subject) return;

    events.emit(EMBED_THREAD_FROM_CMDK, { id, label: subject });
    setIsCommandMenuVisible(false);
    resetSearchResults();
  };

  const handleDialogKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      const selectedItem = ref?.current?.querySelector(
        `${ITEM_SELECTOR}[aria-selected="true"]`,
      );
      if (selectedItem) {
        if (isCommandMenuVisibleViaMentionPanel) {
          const [threadId] =
            (selectedItem as HTMLElement).dataset?.value?.split(SEPARATOR) ??
            [];

          if (!threadId) return;

          handleThreadEmbed(threadId);

          return;
        }
        const event = new Event(SELECT_EVENT);
        selectedItem.dispatchEvent(event);
      }
    }
    if (e.key === "Escape") {
      e.stopPropagation();
      e.preventDefault();

      if (isCommandMenuVisibleViaMentionPanel) {
        chatBoxCommands?.focus();
      }
      setIsCommandMenuVisible(false);
      resetSearchResults();
      stateRef.current.isLoaded = false;
      stateRef.current.userInput = "";
    }
  };

  const resetSearchResults = () => {
    if (areFiltersApplied) return;
    setSearchResults([...[]]);
  };

  const handleSelectSetSearchQueryToPage = () => {
    setFiltersByType("channels", stateRef.current?.filters.channels);
    setFiltersByType("users", stateRef.current?.filters?.users);
    setFiltersByType("tags", stateRef.current?.filters?.tags);
    setSorting(stateRef.current.sorting);
    setSearchQuery(`${stateRef.current.userInput}`);
    setIsCommandMenuVisible(false);
    // setSearchResults([]);
    stateRef.current.isLoaded = false;
    stateRef.current.userInput = "";
    navigateToPath(null);

    if (typeof window !== "undefined") {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  };

  const isThreadListEmpty = searchResults.length === 0;

  const inputPlaceholder = "Search threads...";

  useEffect(() => {
    const body = document.querySelector("body");
    if (!body || isCommandMenuVisibleViaMentionPanel) {
      return;
    }
    stateRef.current.userInput = searchQuery;
    return () => {
      body.style.pointerEvents = "";
    };
  }, [isCommandMenuVisible, searchQuery]);

  return (
    <Command.Dialog
      ref={ref}
      open={isCommandMenuVisible}
      onOpenChange={handleCloseDialog}
      label="Global Command Menu"
      className={`${styles.dialog} radix-themes`}
      shouldFilter={false}
      onKeyUp={handleDialogKeyUp}
    >
      <Theme
        accentColor="indigo"
        grayColor="slate"
        radius="large"
        panelBackground="solid"
        style={{ borderRadius: "var(--radius-4)" }}
      >
        <Container isOpenViaMentionPanel={isCommandMenuVisibleViaMentionPanel}>
          <Flex direction="column" style={{ position: "relative" }}>
            <BasicEditor
              placeholder={inputPlaceholder}
              onChange={handleSearch}
              value={stateRef.current.userInput}
              canCreateTags={false}
            />

            <div className={styles.shortcutContainer}>
              <KeyboardShortcut
                size="small"
                label="ESC"
                className={styles.shortcut}
              />{" "}
              <Text size="1" className={styles.label}>
                to close
              </Text>
            </div>
            {isSearching && <Spinner className={styles.spinner} />}
          </Flex>
          <Command.List
            className={`${styles.list} ${
              isThreadListEmpty && styles.emptyList
            }`}
          >
            <>
              {!stateRef.current.isLoaded && searchResults.length === 0 && (
                <Command.Loading>Fetching threads…</Command.Loading>
              )}

              {searchResults.length !== 0 &&
                !isCommandMenuVisibleViaMentionPanel && (
                  <Flex
                    width="100%"
                    align="center"
                    justify="between"
                    wrap="wrap"
                  >
                    <CommandMenuItem
                      value="Apply Search filters &nbsp;"
                      icon={HomeIcon}
                      onSelect={handleSelectSetSearchQueryToPage}
                    />
                    {/* <Flex gap="2" my="2" justify="end">
                  <SortingFilter
                    sorting={stateRef.current?.sorting ?? sorting}
                    onChange={handleChangeSort}
                  />
                </Flex> */}
                  </Flex>
                )}

              {searchResults.map(
                ({
                  id,
                  subject,
                  subjectHtml,
                  summaryHtml,
                  reactions,
                  num_chats: messageCount,
                  created_at: createdAt,
                  updated_at: updatedAt,
                }) => (
                  <Command.Item
                    className={`${styles.threadItem} ${
                      isCommandMenuVisibleViaMentionPanel &&
                      styles.mentionPanelThreadItem
                    }`}
                    key={`thread-${id}-${subject}`}
                    value={`${id}${SEPARATOR}${subject}`}
                    onSelect={() => handleOpenThread(id)}
                    style={{ cursor: "pointer" }}
                  >
                    <Text
                      size="3"
                      className={styles.title}
                      dangerouslySetInnerHTML={{
                        __html: subjectHtml || "",
                      }}
                    />
                    <p
                      className={styles.description}
                      dangerouslySetInnerHTML={{
                        __html: summaryHtml || "",
                      }}
                    ></p>
                    <FeedItemFooter
                      reactions={reactions}
                      messageCount={messageCount}
                      createdDate={DateTime.fromSeconds(createdAt).toFormat(
                        "LLL dd, yyyy",
                      )}
                      lastUpdated={formatTimeAgo(
                        DateTime.fromSeconds(updatedAt),
                      )}
                    />
                  </Command.Item>
                ),
              )}
            </>
          </Command.List>
          {stateRef.current.isLoaded &&
            !isSearching &&
            searchResults.length === 0 && (
              <>
                <Command.Empty className={styles.empty}>
                  <Text size="2">No results found</Text>
                </Command.Empty>
                <NoResultsItem onSelect={console.log} />
              </>
            )}
        </Container>
      </Theme>
    </Command.Dialog>
  );
};

const Container = ({
  children,
  isOpenViaMentionPanel,
}: {
  children: ReactNode;
  isOpenViaMentionPanel: boolean;
}) => {
  if (!isOpenViaMentionPanel) return children;
  return (
    <Flex
      direction="column"
      style={{
        backgroundColor: "var(--purple-2)",
        borderRadius: "var(--radius-4)",
        boxShadow: "var(--shadow-6)",
      }}
      p="2"
      gap="2"
    >
      <Flex gap="2" align="center">
        <InsertThreadLinkIcon />
        <Text size="3" style={{ color: "var(--purple-11)" }}>
          {" "}
          Insert a thread link
        </Text>
      </Flex>
      <Flex
        direction="column"
        style={{ backgroundColor: "var(--white-1)" }}
        width="100%"
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default CommandMenu;
