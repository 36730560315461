import React from "react";
import { IconType } from "@/app/components/icons/icon.type";

const FeedStackIcon = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="transparent" />
    <path
      d="M10.22 3.60001H2.87C2.59 3.60001 2.37 3.83001 2.37 4.10001C2.37 4.37001 2.59 4.60001 2.87 4.60001H10.22C10.49 4.60001 10.72 4.38001 10.72 4.10001C10.72 3.82001 10.49 3.60001 10.22 3.60001Z"
      fill="currentColor"
    />
    <path
      d="M2.87 6.04001H8.31C8.59 6.04001 8.81 6.27001 8.81 6.54001C8.81 6.74001 8.7 6.91001 8.52999 6.99001C8.46 7.02001 8.39 7.04001 8.31 7.04001H2.87C2.79 7.04001 2.72 7.02001 2.65 6.99001C2.48 6.91001 2.37 6.74001 2.37 6.54001C2.37 6.27001 2.59 6.04001 2.87 6.04001Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2 0H1.7C0.76 0 0 0.77 0 1.71V9.05C0 9.59 0.25 10.07 0.64 10.37V11.59C0.64 12.2 0.97 12.75 1.46 13.04V13.5C1.46 14.44 2.22 15.21 3.16 15.21H12.74C13.68 15.21 14.44 14.44 14.44 13.5V13.04C14.93 12.74 15.25 12.2 15.25 11.59V10.38C15.65 10.07 15.9 9.59 15.9 9.05V1.71C15.9 0.77 15.13 0 14.2 0ZM14.25 11.59C14.25 11.76 14.19 11.91 14.1 12.03C14 12.16 13.85 12.25 13.68 12.29C13.64 12.3 13.6 12.3 13.55 12.3H2.35C2.3 12.3 2.26 12.3 2.22 12.29C2.05 12.25 1.9 12.16 1.8 12.03C1.7 11.91 1.64 11.76 1.64 11.59V10.74C1.64 10.74 1.68 10.75 1.7 10.75H14.25V11.59ZM2.46 13.5V13.3H13.44V13.5C13.44 13.89 13.12 14.21 12.74 14.21H3.16C2.77 14.21 2.46 13.89 2.46 13.5ZM14.9 9.05C14.9 9.21 14.84 9.36 14.75 9.48C14.63 9.63 14.45 9.73 14.25 9.75H1.64C1.43 9.73 1.26 9.63 1.14 9.47C1.05 9.36 1 9.21 1 9.05V1.71C1 1.32 1.31 1 1.7 1H14.2C14.58 1 14.9 1.32 14.9 1.71V9.05Z"
      fill="currentColor"
    />
  </svg>
);

export default FeedStackIcon;
