import React, { useState } from "react";
import { Button, Dialog, Flex, Text } from "@radix-ui/themes";
import { ExitIcon } from "@radix-ui/react-icons";

import { useAppStore } from "@/app/store";
import { updateUserAPI } from "@/app/services/users";
import { useApp } from "@/app/hooks/useApp";
import { ProfileDetails } from "./ProfleDetails";
import { StructUser } from "@/app/types/Thread.type";

interface ProfileDetailsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  user?: StructUser;
}

export interface ProfileFormValues {
  real_name: string;
  display_name: string;
  time_zone: string;
  email_id: string;
  about_me: string;
  avatar_id: string;
}

export interface ProfileFormFieldsActive {
  real_name: boolean;
  display_name: boolean;
  about_me: boolean;
}

export const ProfileDetailsDialog = (
  props: ProfileDetailsDialogProps,
): React.ReactElement => {
  const { isOpen, onClose, user } = props;
  const { logOut } = useApp();

  const loggedInUserId = useAppStore((state) => state.session?.user?.id);

  const isSelf = loggedInUserId === user?.id;

  const [formValues, setFormValues] = useState<ProfileFormValues>({
    real_name: user?.real_name ?? "",
    display_name: user?.display_name ?? "",
    time_zone: user?.time_zone ?? "America/Los Angeles",
    email_id: user?.email_id ?? "",
    about_me: user?.about_me ?? "",
    avatar_id: user?.avatar_id ?? "",
  });

  const [formActive, setFormActive] = useState<ProfileFormFieldsActive>({
    real_name: false,
    display_name: false,
    about_me: false,
  });

  const handleFormValueChange = async (param: string, value: string) => {
    setFormValues({
      ...formValues,
      [param]: value,
    });
  };

  const saveUserData = async () => {
    if (!user) {
      return;
    }

    try {
      await updateUserAPI({
        user: {
          id: user?.id,
          org_id: user?.org_id,
          ...formValues,
        },
      });
      setFormActive({
        real_name: false,
        display_name: false,
        about_me: false,
      });
    } catch (e) {
      console.log(e);
    } finally {
      onClose();
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Content style={{ maxWidth: 734 }} size="4">
        <ProfileDetails
          user={user}
          isSelf={isSelf}
          formValues={formValues}
          handleFormValueChange={handleFormValueChange}
          formActive={formActive}
          setFormActive={setFormActive}
        />

        {!isSelf ? (
          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button size={"3"} variant="soft" onClick={onClose}>
                Cancel
              </Button>
            </Dialog.Close>
          </Flex>
        ) : (
          <Flex gap="3" mt="8" mr="1" justify="between">
            <Button variant="soft" color="red" size="3" onClick={logOut}>
              <ExitIcon width="16" height="16" />
              <Text>Log out</Text>
            </Button>
            <Flex gap="3" justify="end">
              <Dialog.Close>
                <Button size={"3"} variant="soft" onClick={onClose}>
                  Close
                </Button>
              </Dialog.Close>
              <Dialog.Close>
                <Button size="3" onClick={saveUserData}>
                  Save
                </Button>
              </Dialog.Close>
            </Flex>
          </Flex>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};
