import React from "react";
import styles from "./KeyboardShortcut.module.scss";
import { Text } from "@radix-ui/themes";

interface KeyboardShortcutProps {
  label: string;
  className?: string;
  startIcon?: React.ElementType;
  size?: "small" | "medium";
}

const KeyboardShortcut = ({
  label,
  className,
  startIcon,
  size = "medium",
}: KeyboardShortcutProps) => {
  const StartIcon = startIcon;

  return (
    <kbd className={[styles.keyboardShortcut, className].join(" ")}>
      {StartIcon && <StartIcon className={styles.startIcon} />}
      <Text
        size={size === "small" ? "1" : "2"}
        weight={size === "small" ? "regular" : "medium"}
        style={{
          color: "var(--slate-9)",
        }}
      >
        {label}
      </Text>
    </kbd>
  );
};

export default KeyboardShortcut;
