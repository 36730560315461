import { useAppStore } from "@/app/store";
import { getAvatarImageURL } from "@/app/utils/imageUtils";
import { CameraIcon, CopyIcon, SymbolIcon } from "@radix-ui/react-icons";
import {
  AlertDialog,
  Avatar,
  Button,
  Dialog,
  Flex,
  Grid,
  Link,
  Text,
  TextArea,
  TextField,
} from "@radix-ui/themes";
import React, { RefObject, createRef, useEffect, useState } from "react";
import { useFileUpload } from "@/app/hooks/useFileUpload";
import ProfilePicturePreview from "../../profile/ProfilePicture/ProfilePicturePreview";
import {
  updateInviteSecretAPI,
  updateOrgDetails,
} from "@/app/services/organisation";
import { isEqual, omitBy } from "lodash";
import { StructOrganisation } from "@/app/types/Organisation.type";
import { DateTime } from "luxon";

interface OrgInfoDialogProps {
  onClose: () => void;
}

const formatDate = (date: Date): string => {
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL);
};

const EditableDialog = ({ onClose }: OrgInfoDialogProps) => {
  const organisation = useAppStore((state) => state.organisation);
  const setOrganisation = useAppStore((state) => state.setOrganisation);
  const [data, setData] = useState({ ...organisation });
  const [isSecretDialogVisible, setIsSecretDialogVisible] = useState(false);
  const joinSecret = organisation.join_secret;
  const location = window.location;
  const baseUrl = `${location.protocol}//${location.host}/`;
  const inviteLink = `${baseUrl}join/${joinSecret}`;

  const save = async () => {
    const difference = omitBy(
      data,
      (valueB: any, key: keyof StructOrganisation) =>
        isEqual(organisation[key], valueB),
    );

    await updateOrgDetails(difference);
  };

  const handleUpdateSecret = async () => {
    const updatedOrganisation = await updateInviteSecretAPI();
    setOrganisation(updatedOrganisation);
    setIsSecretDialogVisible(false);
  };

  return (
    <Dialog.Root defaultOpen onOpenChange={onClose}>
      <Dialog.Content style={{ maxWidth: 800 }} size="4">
        <Flex direction="row" gap="9" wrap="wrap">
          <Flex direction="column" gap="3" grow="1">
            <Flex direction="column">
              <Text mb="2" size="6" weight="bold">
                {organisation.name} Settings
              </Text>

              <Text
                size="1"
                mb="3"
                style={{
                  color: "var(--slate-a11)",
                }}
              >
                Created on{" "}
                {formatDate(new Date(organisation?.created_at * 1000))}
              </Text>
            </Flex>

            <TextField.Input
              name="name"
              value={data.name}
              placeholder="Enter organisation name"
              color={isEqual(organisation.name, data.name) ? "gray" : undefined}
              variant={
                isEqual(organisation.name, data.name) ? "soft" : "surface"
              }
              size="3"
              autoComplete="false"
              onChange={(ev) => setData({ ...data, name: ev.target.value })}
            />

            <TextField.Input
              name="hostname"
              value={data.hostname}
              placeholder="Enter Hostname..."
              color={
                isEqual(organisation.hostname, data.hostname)
                  ? "gray"
                  : undefined
              }
              variant={
                isEqual(organisation.hostname, data.hostname)
                  ? "soft"
                  : "surface"
              }
              size="3"
              autoComplete="false"
              onChange={(ev) => setData({ ...data, hostname: ev.target.value })}
            />

            <Flex direction="column">
              <Text as="div" size="3" mb="2" mt="4" weight="bold">
                About us
              </Text>
              <Grid columns="1fr 15px" gap="1" align="baseline">
                <TextArea
                  size="3"
                  placeholder="Enter description..."
                  color={
                    isEqual(organisation.description, data.description)
                      ? "gray"
                      : undefined
                  }
                  variant={
                    isEqual(organisation.description, data.description)
                      ? "soft"
                      : "surface"
                  }
                  value={data.description}
                  onChange={(ev) =>
                    setData({ ...data, description: ev.target.value })
                  }
                />
              </Grid>
            </Flex>
          </Flex>

          <ProfilePicture
            src={organisation.avatar_id}
            fallbackSrc={organisation?.name ?? ""}
            onSaveClick={(avatar_id) => {
              setData({ ...data, avatar_id });
            }}
          />
        </Flex>

        <Flex
          direction="column"
          pt="5"
          style={{
            borderTop: "1px solid var(--indigo-a4)",
            paddingTop: 20,
            marginTop: 30,
          }}
        >
          <Text size="3" mb="2" weight="bold">
            Invite new members
          </Text>
          <Text
            size="2"
            mb="3"
            style={{ maxWidth: 350, color: "var(--slate-a11)" }}
          >
            Send this url containing your organization&apos;s secret phrase to
            people you want to invite. Once they click on it, they&apos;re in.
          </Text>

          <Flex>
            <div
              style={{
                padding: "var(--space-3)",
                borderRadius: "var(--radius-5)",
                border: "1px solid var(--indigo-a4)",
                width: "100%",
              }}
            >
              <Flex align="center" justify="between" gap="2">
                <Link
                  href=""
                  size="1"
                  weight="medium"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {inviteLink}
                </Link>

                <Flex gap="2">
                  <Button
                    size="1"
                    onClick={() => navigator.clipboard.writeText(inviteLink)}
                  >
                    <CopyIcon />
                    Copy
                  </Button>

                  <Button
                    size="1"
                    variant="soft"
                    onClick={() => setIsSecretDialogVisible(true)}
                  >
                    <SymbolIcon />
                    Regenerate
                  </Button>
                </Flex>
              </Flex>
            </div>
          </Flex>
        </Flex>

        <Flex justify="between" align="center" mt="4">
          <Dialog.Close>
            <Button size="3" onClick={save} variant="soft">
              Cancel
            </Button>
          </Dialog.Close>
          <Flex justify="end" align="center">
            <Button
              size="3"
              onClick={() => setData({ ...organisation })}
              variant="soft"
              mr="2"
              disabled={isEqual(organisation, data)}
            >
              Reset
            </Button>
            <Dialog.Close>
              <Button
                size="3"
                onClick={save}
                disabled={isEqual(organisation, data)}
              >
                Save
              </Button>
            </Dialog.Close>
          </Flex>
        </Flex>

        {isSecretDialogVisible && (
          <AlertDialog.Root defaultOpen open>
            <AlertDialog.Content style={{ maxWidth: 450 }}>
              <AlertDialog.Title>Update Secret Phrase</AlertDialog.Title>
              <AlertDialog.Description size="2">
                Are you sure? This will invalidate the current invite URL.
              </AlertDialog.Description>

              <Flex gap="3" mt="4" justify="end">
                <AlertDialog.Cancel>
                  <Button
                    variant="soft"
                    color="gray"
                    onClick={() => setIsSecretDialogVisible(false)}
                  >
                    Cancel
                  </Button>
                </AlertDialog.Cancel>
                <AlertDialog.Action>
                  <Button
                    variant="solid"
                    color="red"
                    onClick={handleUpdateSecret}
                  >
                    Update
                  </Button>
                </AlertDialog.Action>
              </Flex>
            </AlertDialog.Content>
          </AlertDialog.Root>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default EditableDialog;

interface ProfilePictureProps {
  src: string;
  fallbackSrc: string;
  onSaveClick: (src: string) => void;
}

const ProfilePicture = ({
  fallbackSrc,
  onSaveClick,
  src,
}: ProfilePictureProps) => {
  const [profilePicSrc, setProfilePicSrc] = useState<string>(src);
  const { handleFileSelect, selectedFiles, fileUrls } = useFileUpload({
    isSingleSelect: true,
  });

  const fileInputRef: RefObject<HTMLInputElement> = createRef();

  useEffect(() => {
    if (
      selectedFiles[0] &&
      !selectedFiles[0].isLoading &&
      profilePicSrc !== fileUrls[0]
    ) {
      setProfilePicSrc(fileUrls[0]);
      onSaveClick(fileUrls[0]);
    }
  }, [selectedFiles[0]?.isLoading]);

  useEffect(() => {
    setProfilePicSrc(src);
  }, [src]);

  const triggerFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Flex direction="column" align="start" gap="2">
        <Flex direction="column" position="relative" mb="2">
          {selectedFiles[0] && selectedFiles[0].isLoading ? (
            <ProfilePicturePreview img={selectedFiles[0]} />
          ) : (
            <Avatar
              src={getAvatarImageURL(profilePicSrc, 128)}
              fallback={fallbackSrc}
              radius="large"
              size={`9`}
            />
          )}
        </Flex>
        <Button
          style={{
            width: "100%",
          }}
          size="2"
          variant="soft"
          onClick={triggerFileSelect}
        >
          <CameraIcon height="16" width="16" />
          <Text size="2">Upload picture</Text>
        </Button>
        <input
          type="file"
          accept={"image/*"}
          ref={fileInputRef}
          onChange={handleFileSelect}
          style={{ display: "none" }}
        />
      </Flex>
    </>
  );
};
