import React from "react";
import { Button, Flex, Text } from "@radix-ui/themes";
import { SymbolIcon } from "@radix-ui/react-icons";
import { useAppStore } from "@/app/store";

/**
 * This button would be visible when:
 * 1. Websocket disconnection for more than 30 seconds. // The app is active
 * 2. There's a version change in the app.
 * 3. If client does not receive ping for more than 30 minutes. // The app is inactive, closed lid or something.
 */

const ResyncButton = () => {
  const shouldResync = useAppStore((state) => state.shouldResync);

  if (!shouldResync) return null;

  return (
    <Flex mb="3" px="2" width="100%">
      <Button
        size="2"
        variant="soft"
        highContrast
        style={{
          background: "var(--red-a4)",
          width: "100%",
          cursor: "pointer",
        }}
        radius="large"
        onClick={() => window.location.reload()}
      >
        <SymbolIcon />
        <Text style={{ color: "var(--red-12)" }} weight="regular">
          Resync for latest
        </Text>
      </Button>
    </Flex>
  );
};

export default ResyncButton;
