import { whoamiAPI } from "@/app/services/auth";
import { Session } from "@/app/types/session";
import isEmpty from "lodash.isempty";

const getSession = async (): Promise<Session | null> => {
  let data;

  try {
    data = await whoamiAPI();
  } catch (error) {
    console.error("Error in whoamiAPI:", error);
    throw new Error("Error in whoamiAPI");
  }

  const user = data?.user as Session["user"];

  if (!data || (isEmpty(user) && isEmpty(data.orgs))) {
    return null;
  }

  // @ts-ignore
  return {
    ...data,
    isModerator: Boolean(user?.bits?.user_moderator),
    isAdmin: Boolean(user?.bits?.user_admin),
    isOwner: Boolean(user?.bits?.user_owner),
  };
};

export default getSession;
