import { useEffect, useState } from "react";

import "./SideBar.custom.scss";

import { useApp } from "@/app/hooks/useApp";
import { useAllFilters } from "@/app/hooks/useAllFilters";
import { useTheme } from "next-themes";
import useFeeds from "@/app/hooks/useFeeds";
import { usePathname, useRouter } from "next/navigation";
import {
  getLocalStorageState,
  setLocalStorageState,
} from "@/app/utils/localStorage";
import { useAppStore } from "@/app/store";
import { DEFAULT_FEED_ID, DEFAULT_SEARCH_FEED_ID } from "@/app/store/App.store";
import useShallowNavigation from "@/app/hooks/useShallowNavigation";
import { StructFeed } from "@/app/types/Feed.type";
import {
  Badge,
  Button,
  Card,
  DropdownMenu,
  Flex,
  Box,
  IconButton,
  Text,
  ScrollArea,
  Tooltip,
} from "@radix-ui/themes";
import {
  Cross1Icon,
  DotsVerticalIcon,
  EnterIcon,
  Cross2Icon,
  MagnifyingGlassIcon,
  MixerHorizontalIcon,
  MoonIcon,
  SunIcon,
  TrashIcon,
  PlusIcon,
  HomeIcon,
  DownloadIcon,
} from "@radix-ui/react-icons";
import events from "@/app/utils/events";
import {
  FORCE_REFRESH_FEED,
  TRIGGER_FEED_FILTERS_MODAL,
} from "@/app/eventConstants";
import Header from "@/app/components/SideBar/Header";
import Link from "next/link";
import styles from "@/app/components/SideBar/SideBar.module.scss";
import ChannelGroupPeopleIcon from "@/app/components/icons/ChannelGroupPeopleIcon";
import FeedPreviewSearchIcon from "@/app/components/icons/FeedPreviewSearchIcon";
import FeedStackIcon from "@/app/components/icons/FeedStackIcon";
import VideoTutorialButton from "@/app/components/SideBar/VideoTutorialButton";
import Toggle from "@/app/components/Toggle";
import UserBlock from "@/app/components/SideBar/UserBlock";
import { getAvatarImageURL } from "@/app/utils/imageUtils";
import CreateFeedDialog from "@/app/components/CreateFeedDialog/CreateFeedDialog";
import ConfirmationDialog from "@/app/components/ConfirmationDialog";
import emojiStyles from "@/app/styles/emoji.module.scss";
import MobileHeader from "./MobileHeader";
import ResyncButton from "./ResyncButton";
import { isDesktopPlatform } from "@/app/constants";
import NotificationToggle from "./NotificationToggle";

const Sidebar = () => {
  const { logIn, featureToggles } = useApp();
  const { areFiltersApplied, removeAllFilters } = useAllFilters();

  const { setTheme, theme } = useTheme();
  const { deleteFeed } = useFeeds();
  const pathname = usePathname();
  const localState = getLocalStorageState();
  const router = useRouter();

  const isAuthenticated = useAppStore((state) => state.isAuthenticated);
  const session = useAppStore((state) => state.session);
  const setIsCommandMenuVisible = useAppStore(
    (state) => state.setIsCommandMenuVisible,
  );
  const currentFeedId = useAppStore((state) => state.currentFeedId);
  const setCurrentFeedId = useAppStore((state) => state.setCurrentFeedId);

  const unreadThreadCountByFeedId = useAppStore(
    (state) => state.unreadThreadCountByFeedId,
  );

  const isAuthenticatedWithCorrectOrg = useAppStore(
    (state) => state.isAuthenticatedWithCorrectOrg,
  );

  const shouldUpgrade = useAppStore((state) => state.shouldUpgrade);

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  let feeds = useAppStore((state) => state.feeds);

  if (
    isAuthenticatedWithCorrectOrg &&
    areFiltersApplied &&
    feeds?.[0]?.id !== DEFAULT_SEARCH_FEED_ID
  ) {
    feeds.unshift({
      ...feeds[0],
      id: DEFAULT_SEARCH_FEED_ID,
      name: "Current Search",
    });
  }

  if (
    isAuthenticatedWithCorrectOrg &&
    !areFiltersApplied &&
    feeds?.[0]?.id === DEFAULT_SEARCH_FEED_ID
  ) {
    feeds.shift();
  }

  const { currentPath, navigateToPath } = useShallowNavigation();
  const [feedToDelete, setFeedToDelete] = useState<StructFeed | null>(null);

  useEffect(() => {
    if (localState?.theme) {
      setTheme(localState?.theme);
    }
  }, [localState.feedMode, localState.theme]);

  useEffect(() => {
    if (areFiltersApplied) {
      setCurrentFeedId(DEFAULT_SEARCH_FEED_ID);
    } else {
      setCurrentFeedId(DEFAULT_FEED_ID);
    }
  }, [areFiltersApplied]);

  const handleThemeChange = (theme: string) => {
    setTheme(theme);
    setLocalStorageState({ theme });
  };

  const handleSearchButtonClick = () => {
    setIsCommandMenuVisible(true);
    setSidebarOpen(false);
  };

  const handleEditFeed = (feedId: StructFeed["id"]) => {
    if (!feedId) {
      return;
    }

    const feed = feeds.find(({ id }) => id === feedId);

    events.emit(TRIGGER_FEED_FILTERS_MODAL, {
      filters: feed?.filters,
      feedId,
      name: feed?.name,
      emoji: feed?.emoji,
      bits: feed?.bits,
      entityMap: feed?.entity_map,
    });
  };

  const handleSwitchFeed = (feedId: StructFeed["id"]) => {
    navigateToPath(null);
    setCurrentFeedId(feedId);
    setSidebarOpen(false);

    if (pathname !== "/") {
      router.push("/");
    }

    // In case user is at current feed, and not in the feed view, we perform force re-render of feed
    if (currentFeedId === feedId && !currentPath) {
      events.emit(FORCE_REFRESH_FEED, { currentFeedId });
    }
  };

  const handleDeleteFeed = async (feedId: StructFeed["id"]) => {
    await deleteFeed({ id: feedId });
    setFeedToDelete(null);
  };

  const hasPageLinksVisible =
    featureToggles.isMembersPageEnabled || featureToggles.isChannelsPageEnabled;

  return (
    <>
      {!isSidebarOpen && (
        <MobileHeader
          handleOpen={() => setSidebarOpen(true)}
          label={feeds.find((feed) => feed.id === currentFeedId)?.name}
        />
      )}

      <Flex
        pl="3"
        pt="1"
        direction="column"
        className={`${styles.sidebar} ${isSidebarOpen ? styles.open : ""}`}
      >
        <Flex justify="between" align="center">
          <Header />
          {isSidebarOpen && (
            <Flex display={{ initial: "flex", sm: "none" }}>
              <Button variant="ghost" onClick={() => setSidebarOpen(false)}>
                <Cross2Icon />
              </Button>
            </Flex>
          )}
        </Flex>
        <Flex direction="column" pt="5" pr="3">
          <ResyncButton />
          {hasPageLinksVisible && (
            <Flex direction="column">
              {featureToggles.isMembersPageEnabled && (
                <Link
                  onClick={() => setSidebarOpen(false)}
                  href="/m"
                  className={styles.menuLink}
                  shallow
                  style={{
                    background:
                      pathname === "/m" ? "var(--indigo-a4)" : undefined,
                  }}
                >
                  <ChannelGroupPeopleIcon />
                  Members
                </Link>
              )}
              {featureToggles.isChannelsPageEnabled && (
                <Link
                  onClick={() => setSidebarOpen(false)}
                  href="/c"
                  className={styles.menuLink}
                  shallow
                  style={{
                    background:
                      pathname === "/c" ? "var(--indigo-a4)" : undefined,
                  }}
                >
                  <ChannelGroupPeopleIcon />
                  Channels
                </Link>
              )}
              {!isDesktopPlatform && (
                <Link
                  target="_blank"
                  href="https://struct.ai/download"
                  className={styles.menuLink}
                >
                  <DownloadIcon />
                  Download app
                </Link>
              )}

              {isDesktopPlatform && shouldUpgrade && (
                <Link
                  target="_blank"
                  href="https://struct.ai/download"
                  className={styles.menuLink}
                  style={{
                    background: "var(--red-a4)",
                    alignItems: "center",
                  }}
                >
                  <DownloadIcon />
                  Upgrade App
                </Link>
              )}
            </Flex>
          )}
        </Flex>
        <Flex
          pt="5"
          style={{
            overflowY: "auto",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ScrollArea type="auto" scrollbars="vertical" size="1">
            <Box width="100%">
              <Flex direction="column" pr="3" width="100%">
                {feeds &&
                  feeds.map((feed) => {
                    const isActive =
                      currentFeedId === feed.id && pathname === "/";
                    const unreadCount =
                      unreadThreadCountByFeedId?.[feed?.id] ?? 0;
                    const hasUnread = unreadCount > 0;

                    return (
                      <Tooltip
                        key={feed.id}
                        content={feed.name}
                        disableHoverableContent
                        side="right"
                        defaultOpen={false}
                        delayDuration={0}
                      >
                        <Flex
                          key={feed.id}
                          align="center"
                          justify="between"
                          className={styles.menuButtonContainer}
                          gap="1"
                        >
                          <Button
                            className={styles.menuButton}
                            radius="large"
                            variant="outline"
                            highContrast
                            onClick={() => handleSwitchFeed(feed.id)}
                            style={{
                              boxShadow: "none",
                              flex: 1,
                              ...(isActive && {
                                background: "var(--indigo-a4)",
                              }),
                              justifyContent: "flex-start",
                              paddingRight: "var(--space-1)",
                            }}
                          >
                            <Flex>
                              {feed.emoji ? (
                                <Text mr="-1" className={emojiStyles.notoEmoji}>
                                  {feed.emoji}
                                </Text>
                              ) : feed.id === DEFAULT_SEARCH_FEED_ID ? (
                                <FeedPreviewSearchIcon />
                              ) : feed.id === DEFAULT_FEED_ID ? (
                                <HomeIcon />
                              ) : (
                                <FeedStackIcon />
                              )}
                            </Flex>
                            <Text
                              as="p"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                              weight={hasUnread ? "bold" : undefined}
                            >
                              {feed.name}
                            </Text>

                            {hasUnread && (
                              <Badge color="indigo" variant="solid" ml="auto">
                                {unreadCount}
                              </Badge>
                            )}
                          </Button>

                          {feed.id === DEFAULT_SEARCH_FEED_ID && (
                            <IconButton
                              className={styles.menuButtonPopoverTrigger}
                              variant="soft"
                              style={{
                                ...(isActive && {
                                  visibility: "visible",
                                }),
                              }}
                              onClick={removeAllFilters}
                            >
                              <Cross1Icon />
                            </IconButton>
                          )}

                          {Boolean(feed.bits.feed_special) || (
                            <DropdownMenu.Root>
                              <DropdownMenu.Trigger>
                                <IconButton
                                  className={styles.menuButtonPopoverTrigger}
                                  variant="soft"
                                  style={{
                                    ...(isActive && {
                                      visibility: "visible",
                                      display: "flex",
                                    }),
                                  }}
                                >
                                  <DotsVerticalIcon />
                                </IconButton>
                              </DropdownMenu.Trigger>
                              <DropdownMenu.Content>
                                <DropdownMenu.Item
                                  onClick={() => handleEditFeed(feed.id)}
                                >
                                  Edit
                                  <Flex ml="3" align="center">
                                    <MixerHorizontalIcon />
                                  </Flex>
                                </DropdownMenu.Item>
                                <DropdownMenu.Item
                                  onClick={() => {
                                    setFeedToDelete(feed);
                                  }}
                                >
                                  Delete
                                  <Flex ml="3" align="center">
                                    <TrashIcon />
                                  </Flex>
                                </DropdownMenu.Item>
                              </DropdownMenu.Content>
                            </DropdownMenu.Root>
                          )}
                        </Flex>
                      </Tooltip>
                    );
                  })}

                {feeds.length > 0 && (
                  <Flex
                    align="center"
                    justify="between"
                    className={styles.menuButtonContainer}
                    style={{ background: "var(--slate-a2)" }}
                    gap="1"
                  >
                    <Button
                      radius="large"
                      variant="outline"
                      highContrast
                      onClick={() =>
                        events.emit(TRIGGER_FEED_FILTERS_MODAL, {})
                      }
                      style={{
                        boxShadow: "none",
                        flex: 1,
                        justifyContent: "flex-start",
                        padding: "0 var(--space-3)",
                      }}
                    >
                      <IconButton
                        style={{
                          width: "var(--space-4)",
                          height: "var(--space-4)",
                        }}
                        size="1"
                        asChild
                      >
                        <PlusIcon />
                      </IconButton>
                      <Text>Create a feed</Text>
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Box>
          </ScrollArea>
        </Flex>
        <Flex m="2">
          <VideoTutorialButton />
        </Flex>
        <Flex direction="column" align="center" pr="3">
          <Card asChild mb="4" style={{ width: "100%" }}>
            <button onClick={() => handleSearchButtonClick()}>
              <Flex align="center" justify="between">
                <Flex align="center" gap="1">
                  <MagnifyingGlassIcon />
                  <Text size="2">Search threads</Text>
                </Flex>
                {/* keyboard shortcut */}
                <Badge
                  size="1"
                  variant="soft"
                  style={{
                    backgroundColor: "var(--slate-a3)",
                    color: "var(--slate-11)",
                    marginRight: "calc(var(--space-4) * -1)",
                  }}
                >
                  cmd+k
                </Badge>
              </Flex>
            </button>
          </Card>
          <Flex
            mb="4"
            gap="2"
            justify="center"
            style={{
              width: "100%",
            }}
          >
            <NotificationToggle />
            <Toggle
              selectedIndex={theme !== "light" ? 1 : 0}
              onCheckedChange={() =>
                handleThemeChange(theme === "dark" ? "light" : "dark")
              }
              items={[
                {
                  description: "Light Mode",
                  icon: (
                    <Flex ml="1">
                      <SunIcon />
                    </Flex>
                  ),
                },
                {
                  description: "Dark Mode",
                  icon: (
                    <Flex mr="1">
                      <MoonIcon />
                    </Flex>
                  ),
                },
              ]}
            />
          </Flex>
          {isAuthenticated && (
            <UserBlock
              avatarSrc={getAvatarImageURL(session?.user?.avatar_id as string)}
              fallback={session?.user?.display_name?.[0] as string}
              alt={`Photo of ${session!.user?.avatar_id}`}
              email={session!.email}
            />
          )}
          {!isAuthenticated && (
            <Button
              mb="1"
              style={{
                width: "100%",
              }}
              size="1"
              onClick={() => logIn()}
            >
              <EnterIcon /> Log in
            </Button>
          )}
        </Flex>
        <CreateFeedDialog />
        {feedToDelete && (
          <ConfirmationDialog
            title={`Are you sure about deleting the “${feedToDelete.name}” feed?`}
            onCancel={() => setFeedToDelete(null)}
            onConfirm={() => handleDeleteFeed(feedToDelete.id)}
          />
        )}
      </Flex>
    </>
  );
};

export default Sidebar;
