import React from "react";
import { Flex } from "@radix-ui/themes";
import { FileUploadAnimation } from "@/app/components/ChatBox/FileUploadAnimation";
import styles from "./ProfilePicture.module.scss";

export interface SelectedFile {
  file: File;
  url: string;
  isLoading: boolean;
}

interface ProfilePicturePreviewProps {
  img: SelectedFile;
}

const ProfilePicturePreview = ({ img }: ProfilePicturePreviewProps) => (
  <div className={styles.profilePicturePreview}>
    <Flex
      className={styles.imagePreviewContent}
      style={{
        opacity: img.isLoading ? 0.5 : 1,
      }}
    >
      <img className={styles.imagePreview} src={img.url} alt="Preview" />
    </Flex>

    {img.isLoading && (
      <FileUploadAnimation
        style={{
          position: "absolute",
        }}
      />
    )}
  </div>
);

export default ProfilePicturePreview;
