import { useAppStore } from "@/app/store";
import { getAvatarImageURL } from "@/app/utils/imageUtils";
import { ChatBubbleIcon, Link1Icon } from "@radix-ui/react-icons";
import { Avatar, Button, Flex, Text, Dialog } from "@radix-ui/themes";
import React from "react";
import PenIllustration from "../../illustrations/PenIllustration";
import { DateTime } from "luxon";

interface OrgInfoDialogProps {
  onClose: () => void;
}

const formatDate = (date: Date): string => {
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL);
};

const ReadOnlyDialog = ({ onClose }: OrgInfoDialogProps) => {
  const organisation = useAppStore((state) => state.organisation);

  return (
    <Dialog.Root defaultOpen onOpenChange={onClose}>
      <Dialog.Content style={{ maxWidth: 500 }} size="4">
        <Flex
          p="4"
          align="center"
          style={{
            backgroundColor: "var(--indigo-2)",
            borderRadius: "var(--radius-3)",
            position: "relative",
          }}
          direction="column"
        >
          <Avatar
            src={getAvatarImageURL(organisation?.avatar_id, 128)}
            fallback={organisation?.name?.[0]}
            size="7"
            alt={organisation?.name}
          />
          <Text size="6" mt="4" weight="medium">
            {organisation?.name}
          </Text>
          <Text
            size="1"
            mb="3"
            style={{
              color: "var(--slate-a11)",
            }}
          >
            {formatDate(new Date(organisation?.created_at * 1000))}
          </Text>

          <Flex gap="2" align="center" mb="3">
            <ChatBubbleIcon />
            <Text
              size="2"
              style={{
                color: "var(--slate-a11)",
              }}
            >
              {organisation.num_threads} threads
            </Text>
          </Flex>

          <Flex
            px="2"
            py="1"
            style={{
              backgroundColor: "var(--white-1)",
              borderRadius: "var(--radius-2)",
            }}
          >
            <Flex gap="2" align="center">
              <Link1Icon
                style={{
                  color: "var(--indigo-a7)",
                }}
              />
              <Text
                style={{
                  color: "var(--indigo-a11)",
                }}
              >
                {organisation?.hostname}
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" align="center" p="4" gap="4">
          <PenIllustration />

          <Text
            size="2"
            style={{
              color: "var(--slate-12)",
            }}
          >
            {organisation?.description}
          </Text>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Close
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ReadOnlyDialog;
