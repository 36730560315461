import { Flex, Text, Separator, Badge, Select, Button } from "@radix-ui/themes";
import React, { ElementType, useMemo, useState } from "react";
// todo: move away from antd/Select and create something internally
import { Select as AntSelect, Spin } from "antd";
import { PlusIcon } from "@radix-ui/react-icons";
import { CrossSmallIcon } from "../icons/CrossSmallIcon";
import { v4 as uuidv4 } from "uuid";
import { Kind, SelectedItems } from "./types";
import { debounce } from "lodash";

const filterTypes = [
  { label: "Any of", value: "any_of" },
  { label: "All of", value: "all_of" },
];

const filterOption = (input: string, option: any) =>
  (option?.name ?? "").toLowerCase().includes(input.toLowerCase());

const ANY_OF = "any_of";
const ALL_OF = "all_of";
const NONE_OF = "none_of";

interface Props {
  Icon: ElementType;
  label: string;
  allItems: any[];
  selectedItems: SelectedItems;
  setSelectedItems: (value: SelectedItems) => void;
  placeholder: string;
  onSearch?: (value: string) => void;

  excludePlaceholder: string;
}

const RefineFilterBlock = ({
  Icon,
  label,
  allItems,
  selectedItems,
  setSelectedItems,
  placeholder,
  excludePlaceholder,
  onSearch,
}: Props) => {
  const [isFetching, setFetching] = useState(false);

  const noneOfIndex = selectedItems.findIndex((item) => item.kind === NONE_OF);
  const excludedItems = selectedItems[noneOfIndex]?.values ?? null;

  const updateSelectedItems = (index: number, items: string[]) => {
    selectedItems[index].values = items;
    setSelectedItems(selectedItems);
  };

  const updateType = (newKind: Kind, index: number) => {
    selectedItems[index].kind = newKind;
    setSelectedItems(selectedItems);
  };

  const removeItemsFromKind = (index: number) => {
    selectedItems?.splice(index, 1);
    setSelectedItems(selectedItems);
  };

  const addItemsToKind = (kind: Kind, items: string[]) => {
    selectedItems?.push({ kind, values: items });
    setSelectedItems(selectedItems);
  };

  const debounceSearch = useMemo(() => {
    const loadOptions = async (value: string) => {
      if (!onSearch) return;
      setFetching(true);
      await onSearch(value);
      setFetching(false);
    };

    return debounce(loadOptions, 600);
  }, [onSearch]);

  return (
    <Flex direction="column" gap="3" id="filter-block">
      <Flex align="center" gap="2" mt="2">
        <Icon
          style={{
            color: "var(--slate-11)",
          }}
        />
        <Text
          size="2"
          style={{
            color: "var(--slate-11)",
          }}
        >
          {label}
        </Text>
      </Flex>
      {selectedItems.map(({ kind, values: items }, kindIndex) => {
        if (!filterTypes.map((filterType) => filterType.value).includes(kind))
          return null;

        const canRemove = kindIndex !== 0;
        return (
          <Flex align="center" key={items.join(", ")}>
            <Select.Root
              defaultValue={kind}
              size="1"
              onValueChange={(newKind) => updateType(newKind, kindIndex)}
            >
              <Select.Trigger />
              <Select.Content>
                <Select.Group>
                  {filterTypes.map((filterType) => (
                    <Select.Item
                      key={filterType.value}
                      value={filterType.value}
                    >
                      {filterType.label}
                    </Select.Item>
                  ))}
                </Select.Group>
              </Select.Content>
            </Select.Root>
            <Separator />
            <Flex width={"100%"} align="center" gap="4">
              <AntSelect
                placement="bottomLeft"
                filterOption={filterOption}
                style={{ width: "100%" }}
                mode="multiple"
                value={items}
                onChange={(a, items: any[]) => {
                  updateSelectedItems(
                    kindIndex,
                    items.map((item) => item.value),
                  );
                }}
                onSearch={debounceSearch}
                dropdownAlign={{
                  overflow: { adjustX: false, adjustY: false },
                }}
                options={allItems}
                placeholder={placeholder}
                dropdownStyle={{ marginTop: "-8px" }}
                notFoundContent={isFetching ? <Spin size="small" /> : null}
                getPopupContainer={() =>
                  document.getElementById("filter-block") as HTMLElement
                }
              />
              {canRemove && (
                <Button
                  size="1"
                  color="red"
                  variant="ghost"
                  onClick={() => removeItemsFromKind(kindIndex)}
                >
                  Remove
                  <CrossSmallIcon />
                </Button>
              )}
            </Flex>
          </Flex>
        );
      })}

      <Flex justify="end" align="center" gap="3">
        <Button
          variant="outline"
          onClick={() => {
            addItemsToKind(ANY_OF, []);
          }}
          size="1"
        >
          <PlusIcon />
          Add Condition
        </Button>
        {!excludedItems && (
          <Button
            variant="outline"
            onClick={() => {
              addItemsToKind(NONE_OF, []);
            }}
            size="1"
          >
            <PlusIcon />
            Add Exclusion
          </Button>
        )}
      </Flex>

      {excludedItems && (
        <Flex align="center">
          <Badge color="crimson">Exclude</Badge>
          <Separator size="1" />
          <Flex width="100%" mr="4">
            <AntSelect
              placement="bottomLeft"
              dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
              filterOption={filterOption}
              style={{ width: "100%" }}
              mode="multiple"
              value={excludedItems}
              onChange={(a, items: any[]) => {
                updateSelectedItems(
                  noneOfIndex,
                  items.map((item) => item.value),
                );
              }}
              options={allItems}
              placeholder={excludePlaceholder}
              getPopupContainer={() =>
                document.getElementById("filter-block") as HTMLElement
              }
            />
          </Flex>
          <Button
            size="1"
            color="red"
            variant="ghost"
            onClick={() => {
              removeItemsFromKind(noneOfIndex);
            }}
          >
            Remove
            <CrossSmallIcon />
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
export default RefineFilterBlock;
