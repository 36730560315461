"use client";
import React, { useEffect } from "react";
import { ThemeProvider as NextThemeProvider, useTheme } from "next-themes";

interface ThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    const systemTheme = window.matchMedia("(prefers-color-scheme: dark)");
    if ((systemTheme.matches && theme === "system") || theme === "dark") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, [setTheme, theme]);

  return <NextThemeProvider attribute="class">{children}</NextThemeProvider>;
};

export default ThemeProvider;
