import { toHTML } from "@/app/modules/slack-markdown";
import { SearchDocument } from "@/app/types/Search.type";

export const getConvertedSearchResults = (
  searchResults: SearchDocument[],
): SearchDocument[] =>
  searchResults.map((hit) => {
    const subjectHtml = toHTML(hit.subject);

    const summaryHtml = toHTML(hit.summary);

    return { ...hit, subjectHtml, summaryHtml };
  });
