import { StructUser } from "@/app/types/Thread.type";
import { STRUCT_API_VERSION } from "@/app/constants";
import { AvatarListResponse } from "../types/Avatar.type";
import { structAPIFetchClient } from "./client";
import { StructChannel } from "../types/Channel.type";

const USERS_ENDPOINT = `/${STRUCT_API_VERSION}/users`;

interface GetUserAPI {
  query?: string;
  channelId?: string;
  bits?: string;
  sortBy?: string;
  orderBy?: string;
  limit?: number;
  offset?: number;
}

export const getAllUsersAPI = ({
  query,
  channelId,
  bits,
  sortBy,
  orderBy,
  limit,
  offset,
}: GetUserAPI) => {
  const params = new URLSearchParams();
  if (query) params.append("q", query);
  if (channelId) params.append("channel_id", channelId);
  if (bits) params.append("bits", bits.toString());
  if (sortBy) params.append("sort_by", sortBy);
  if (orderBy) params.append("order_by", orderBy);
  if (limit) params.append("limit", limit.toString());
  if (offset) params.append("offset", offset.toString());

  return structAPIFetchClient(`${USERS_ENDPOINT}?${params.toString()}`, {
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(({ data }) => (data || []) as StructUser[]);
};

export const getUserAPI = ({ userId }: { userId: string }) => {
  const endpoint = `${USERS_ENDPOINT}/${userId}`;
  return structAPIFetchClient(endpoint, {
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => data as StructUser);
};

export const updateUserAPI = ({ user }: { user: Partial<StructUser> }) => {
  return structAPIFetchClient(USERS_ENDPOINT, {
    method: "PUT",
    // @ts-ignore
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(({ data }) => data as StructUser);
};

interface UpdateUserChannelAPI {
  channel_id: StructChannel["id"];
  user_ids: StructUser["id"][];
  action: "add" | "remove";
}

export const updateUsersChannelAPI = (payload: UpdateUserChannelAPI) => {
  return structAPIFetchClient(`${USERS_ENDPOINT}.channel`, {
    method: "POST",
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(({ channel }) => channel as StructChannel);
};

export const getAvatarListAPI = () => {
  const endpoint = `/${STRUCT_API_VERSION}/anons`;
  return structAPIFetchClient(endpoint, {
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => data as AvatarListResponse);
};
