import React from "react";
import { Pencil2Icon } from "@radix-ui/react-icons";
import {
  Flex,
  Grid,
  Select,
  Text,
  TextArea,
  TextField,
} from "@radix-ui/themes";

import { useAppStore } from "@/app/store";
import { ProfilePicture } from "../ProfilePicture/ProfilePicture";
import { GuestProfile } from "./GuestProfile";
import {
  ProfileFormFieldsActive,
  ProfileFormValues,
} from "./ProfileDetailsDialog";
import styles from "./ProfileDetails.module.scss";
import { timezones } from "@/app/utils/timeUtils";
import { StructUser } from "@/app/types/Thread.type";

const formatTz = (timeZone: string) => {
  // to fix a bug where timezone is returned as Calcutta from BE
  // but the browser supports Asia/Kolkata timezone
  if (timeZone.includes("Calcutta")) {
    return timeZone.replace("Calcutta", "Kolkata");
  }
  return timeZone;
};

interface ProfileDetailsProps {
  user?: StructUser;
  isSelf: boolean;
  formValues: ProfileFormValues;
  handleFormValueChange: (param: string, value: string) => void;
  formActive: ProfileFormFieldsActive;
  setFormActive: (profileFromActiveData: ProfileFormFieldsActive) => void;
}

export const ProfileDetails = ({
  user,
  isSelf,
  formValues,
  handleFormValueChange,
  formActive,
  setFormActive,
}: ProfileDetailsProps): React.ReactElement => {
  if (!isSelf) {
    return <GuestProfile user={user} />;
  }

  const handleFormActive = (param: string) => {
    setFormActive({
      ...formActive,
      [param]: true,
    });
  };

  return (
    <Flex direction="column" className={styles.profilePageContainer}>
      <Flex direction="row" gap="9" mt="5" wrap="wrap">
        <ProfilePicture
          src={formValues.avatar_id}
          fallbackSrc={user?.display_name ?? ""}
          onSaveClick={(avatarId) =>
            handleFormValueChange("avatar_id", avatarId)
          }
        />
        <Flex direction="column" gap="3" grow="1">
          <Flex direction="row" justify="between">
            <Text mb="2" size="6" weight="bold">
              My Profile
            </Text>
          </Flex>
          <Grid columns="1fr 15px" gap="1" align="baseline">
            <TextField.Input
              name="fullname"
              color={!formActive.real_name ? "gray" : undefined}
              value={formValues.real_name}
              placeholder="Enter your full name"
              variant={!formActive.real_name ? "soft" : "surface"}
              size="3"
              autoComplete="false"
              onFocus={() => handleFormActive("real_name")}
              onChange={(ev) =>
                handleFormValueChange("real_name", ev.target.value)
              }
            />
            {!formActive.real_name && <Pencil2Icon />}
          </Grid>

          <Grid columns="1fr 15px" gap="1" align="baseline">
            <TextField.Input
              name="display-name"
              color={!formActive.display_name ? "gray" : undefined}
              value={formValues.display_name}
              placeholder="Enter your display name"
              variant={!formActive.display_name ? "soft" : "surface"}
              size="3"
              autoComplete="false"
              onFocus={() => handleFormActive("display_name")}
              onChange={(ev) =>
                handleFormValueChange("display_name", ev.target.value)
              }
            />
            {!formActive.display_name && <Pencil2Icon />}
          </Grid>

          <Grid columns="1fr 15px" gap="1" align="baseline">
            <TextField.Input
              disabled
              name="user-email"
              color="gray"
              autoComplete="false"
              value={formValues.email_id}
              placeholder="Enter your email"
              variant="soft"
              size="3"
              onChange={(ev) =>
                handleFormValueChange("email_id", ev.target.value)
              }
            />
          </Grid>

          <label>
            <Text as="div" size="3" mb="2" mt="6" weight="bold">
              Timezone
            </Text>
            <Select.Root
              size="3"
              name="timezone"
              value={formValues.time_zone}
              onValueChange={(val) => {
                handleFormValueChange("time_zone", val);
              }}
            >
              <Grid columns="1fr 15px" gap="1" align="baseline">
                <Select.Trigger />
              </Grid>
              <Select.Content>
                {timezones.map((tz, index) => {
                  return (
                    <Select.Item key={`timezone-${index}`} value={tz}>
                      {tz}
                    </Select.Item>
                  );
                })}
              </Select.Content>
            </Select.Root>
          </label>

          <Flex direction="column">
            <Text as="div" size="3" mb="2" mt="6" weight="bold">
              About me
            </Text>
            <Grid columns="1fr 15px" gap="1" align="baseline">
              <TextArea
                name="about-me"
                color={!formActive.about_me ? "gray" : undefined}
                placeholder="Enter description"
                onFocus={() => handleFormActive("about_me")}
                variant={!formActive.about_me ? "soft" : "surface"}
                size="3"
                onChange={(ev) =>
                  handleFormValueChange("about_me", ev.target.value)
                }
                value={formValues.about_me}
              />
              {!formActive.about_me && <Pencil2Icon />}
            </Grid>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
