import { createRef, RefObject, useEffect, useState } from "react";
import { Avatar, Button, Flex, Text } from "@radix-ui/themes";
import { AvatarIcon, CameraIcon } from "@radix-ui/react-icons";

import { getAvatarImageURL } from "@/app/utils/imageUtils";
import { useFileUpload } from "@/app/hooks/useFileUpload";
import { AvatarSelector } from "../AvatarSelector/AvatarSelector";
import ProfilePicturePreview from "./ProfilePicturePreview";

interface ProfilePictureProps {
  src: string;
  fallbackSrc: string;
  onSaveClick: (src: string) => void;
}

export const ProfilePicture = ({
  fallbackSrc,
  onSaveClick,
  src,
}: ProfilePictureProps) => {
  const [isAvatarSelectorOpen, setIsAvatarSelectorOpen] =
    useState<boolean>(false);
  const [profilePicSrc, setProfilePicSrc] = useState<string>(src);

  const { handleFileSelect, selectedFiles, fileUrls } = useFileUpload({
    isSingleSelect: true,
  });

  const fileInputRef: RefObject<HTMLInputElement> = createRef();

  useEffect(() => {
    if (
      selectedFiles[0] &&
      !selectedFiles[0].isLoading &&
      profilePicSrc !== fileUrls[0]
    ) {
      setProfilePicSrc(fileUrls[0]);
      onSaveClick(fileUrls[0]);
    }
  }, [selectedFiles[0]?.isLoading]);

  useEffect(() => {
    setProfilePicSrc(src);
  }, [src]);

  const onAvatarSelect = (avatarName: string) => {
    setProfilePicSrc(`anon-${avatarName}`);
    onSaveClick(`anon-${avatarName}`);
    setIsAvatarSelectorOpen(false);
  };

  const triggerFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Flex direction="column" align="start" gap="2">
        <Flex direction="column" position="relative" mb="2">
          {selectedFiles[0] && selectedFiles[0].isLoading ? (
            <ProfilePicturePreview img={selectedFiles[0]} />
          ) : (
            <Avatar
              src={getAvatarImageURL(profilePicSrc, 128)}
              fallback={fallbackSrc}
              radius="large"
              size={`9`}
            />
          )}
        </Flex>
        <Button
          style={{
            width: "100%",
          }}
          size="2"
          variant="soft"
          onClick={triggerFileSelect}
        >
          <CameraIcon height="16" width="16" />
          <Text size="2">Upload picture</Text>
        </Button>
        <Button
          size="2"
          variant="soft"
          style={{
            width: "100%",
          }}
          onClick={() => setIsAvatarSelectorOpen(true)}
        >
          <AvatarIcon height="16" width="16" />
          <Text size="2">Pick avatar</Text>
        </Button>
        <input
          type="file"
          accept={"image/*"}
          ref={fileInputRef}
          onChange={handleFileSelect}
          style={{ display: "none" }}
        />
      </Flex>
      {isAvatarSelectorOpen && (
        <AvatarSelector
          isOpen={isAvatarSelectorOpen}
          onClose={() => setIsAvatarSelectorOpen(false)}
          onAvatarSelect={onAvatarSelect}
        />
      )}
    </>
  );
};
