import { Switch, Flex, Text, Callout, Badge } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import ChannelGroupPeopleIcon from "../icons/ChannelGroupPeopleIcon";
import HashtagIcon from "../icons/HashtagIcon";
import { useAppStore } from "@/app/store";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import RefineFilterBlock from "./RefineFilterBlock";
import { SelectedItems } from "./types";
import { set, uniqBy } from "lodash";
import { getMentions } from "@/app/services/mentions";
import { StructChannel } from "@/app/types/Channel.type";
import { StructUser } from "@/app/types/Thread.type";
import { StructFeed } from "@/app/types/Feed.type";
import { EntityIDMap } from "@/app/constants";

const toggles = [
  { label: "Direct Messages", selector: "feed_dm" },
  { label: "Unread Messages", selector: "feed_unread" },
  { label: "Bookmarks", selector: "thread_bookmarked" },
];

const getChannelsAndUsersFromEntityMap = (
  entityMap: StructFeed["entity_map"],
) => {
  if (!entityMap) {
    return { channels: [], users: [] };
  }
  const entityIds = Object.keys(entityMap);
  const channels: StructChannel[] = [];
  const users: StructUser[] = [];

  for (let index = 0; index < entityIds.length; index++) {
    const entityId = entityIds[index];
    if (entityId.startsWith(EntityIDMap.Channel)) {
      channels.push(entityMap[entityId] as StructChannel);
    } else if (entityId.startsWith(EntityIDMap.User)) {
      users.push(entityMap[entityId] as StructUser);
    }
  }
  return { channels, users };
};

interface Props {
  localFiltersState: any;
  updateLocalFiltersState: (newState: any) => void;
  entityMap: StructFeed["entity_map"];
}

const Row = ({ name, count }: { name: string; count: number }) => {
  return (
    <Flex align="center" justify="between">
      <Text size="2">{name}</Text>
      <Badge size="1" variant="outline" ml="2">
        {count}
      </Badge>
    </Flex>
  );
};

const RefineFiltersStep = ({
  localFiltersState,
  updateLocalFiltersState,
  entityMap,
}: Props) => {
  const [channels, setChannels] = useState<StructChannel[]>([]);
  const [users, setUsers] = useState<StructUser[]>([]);
  const tags = useAppStore((state) => state.tags);

  const fetchMentions = async (query: string, ids = []) => {
    const { channels = [], users = [] } = await getMentions(
      query,
      true,
      [],
      10,
    );
    setChannels((prevChannels) => uniqBy([...prevChannels, ...channels], "id"));
    setUsers((prevUsers) => uniqBy([...prevUsers, ...users], "id"));
  };

  useEffect(() => {
    const { channels, users } = getChannelsAndUsersFromEntityMap(entityMap);
    setChannels((prevChannels) => uniqBy([...prevChannels, ...channels], "id"));
    setUsers((prevUsers) => uniqBy([...prevUsers, ...users], "id"));
    fetchMentions("");
  }, []);

  const updateState = (entity: string, value: SelectedItems) => {
    localFiltersState[entity] = value;
    updateLocalFiltersState(localFiltersState);
  };

  const updateBits = (selector: string, checked: boolean) => {
    set(localFiltersState, `bits.${selector}`, checked);
    updateLocalFiltersState(localFiltersState);
  };

  return (
    <Flex direction="column">
      <Text size="3" weight="bold">
        Refine your feed
      </Text>
      <Flex direction="column" gap="2" mt="3">
        <Flex align="center" gap="3" my="2">
          {toggles.map(({ label, selector }) => (
            <Flex align="center" gap="1" key={selector}>
              <Switch
                size="1"
                checked={localFiltersState?.bits?.[selector]}
                onCheckedChange={(checked) => {
                  updateBits(selector, checked);
                }}
              />
              <Text size="1">{label}</Text>
            </Flex>
          ))}
        </Flex>
        <RefineFilterBlock
          Icon={ChannelGroupPeopleIcon}
          label="Channels or Members"
          allItems={[
            ...channels.map((channel) => ({
              value: channel.id,
              name: "@" + channel.name,
              label: (
                <Row
                  name={"@" + channel.name}
                  count={channel?.num_threads ?? 0}
                />
              ),
            })),
            ...users.map((user) => ({
              value: user.id,
              name: "@" + user.display_name + " | " + user.real_name,
              label: (
                <Row
                  name={"@" + user.display_name + " | " + user.real_name}
                  count={user.num_threads as number}
                />
              ),
            })),
          ]}
          selectedItems={localFiltersState?.ids}
          setSelectedItems={(items) => updateState("ids", items)}
          placeholder="What channels or members should we include"
          excludePlaceholder="What channels or members should we exclude"
          onSearch={fetchMentions}
        />
        <RefineFilterBlock
          Icon={HashtagIcon}
          label="Tags"
          allItems={tags.map(({ tag, count }) => ({
            value: tag,
            name: "#" + tag,
            label: <Row name={"#" + tag} count={count} />,
          }))}
          selectedItems={localFiltersState?.tags}
          setSelectedItems={(items) => updateState("tags", items)}
          placeholder="What tags should we include"
          excludePlaceholder="What tags should we exclude"
        />
      </Flex>
      <Flex my="4">
        <Callout.Root variant="soft" highContrast color="indigo">
          <Callout.Icon>
            <InfoCircledIcon />
          </Callout.Icon>
          <Callout.Text size="1">
            Select &apos;Any of&apos; to display threads with any of the chosen
            items, or &apos;All of&apos; for threads that must include all your
            selections.
          </Callout.Text>
        </Callout.Root>
      </Flex>
    </Flex>
  );
};

export default React.memo(RefineFiltersStep);
