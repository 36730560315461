import { structAPIFetchClient } from "@/app/services/client";
import { STRUCT_API_VERSION } from "@/app/constants";
import { StructTag } from "@/app/types/Tag.type";

const CHANNELS_ENDPOINT = `/${STRUCT_API_VERSION}/tags`;

export const getAllTags = () => {
  return structAPIFetchClient(CHANNELS_ENDPOINT, {
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(({ data }) => data as StructTag[]);
};
