import { structAPIFetchClient } from "@/app/services/client";
import { STRUCT_API_VERSION } from "@/app/constants";
import { StructTag } from "@/app/types/Tag.type";

export const sendTypingStatusAPI = (threadId: string) => {
  const ENDPOINT = `/${STRUCT_API_VERSION}/typing`;
  return structAPIFetchClient(ENDPOINT, {
    method: "PUT",
    body: JSON.stringify({
      thread_id: threadId,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(({ data }) => data as StructTag[]);
};

export const sendPresenceAPI = () => {
  const ENDPOINT = `/${STRUCT_API_VERSION}/presence`;
  return structAPIFetchClient(ENDPOINT, {
    method: "PUT",
    body: JSON.stringify({}),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(({ data }) => data as StructTag[]);
};
