import { IconType } from "@/app/components/icons/icon.type";

const PenIllustration = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="46"
    height="21"
    viewBox="0 0 46 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9634 19.1046L29.8344 17.7588C29.9254 17.6681 28.4816 16.2295 28.3905 16.3202L27.0399 18.1844C26.7863 18.4371 26.7841 18.8497 27.0399 19.1046C27.2936 19.3573 27.7076 19.3594 27.9634 19.1046Z"
      fill="#3A5CCC"
    />
    <path
      d="M28.8891 14.9635L36.4249 7.45512C36.5441 7.33632 36.7392 7.33632 36.8584 7.45512L38.7315 9.32141C38.8508 9.44022 38.8508 9.63462 38.7315 9.75343L31.1958 17.2618C30.6885 17.7672 29.8647 17.7673 29.3574 17.2618L28.887 16.7931C28.3797 16.2876 28.3797 15.4668 28.887 14.9613L28.8891 14.9635Z"
      stroke="var(--black-1)"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.957 9.80543L41.281 7.48984C43.0154 5.7618 45.4391 3.23452 44.2034 2.00329C42.9677 0.772056 40.4312 3.187 38.6969 4.91505L36.3728 7.23064C36.3056 7.2976 36.31 7.41424 36.388 7.492L38.6947 9.79031C38.7706 9.86591 38.8876 9.87455 38.957 9.80543Z"
      fill="var(--black-1)"
      stroke="var(--black-1)"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1.88238 14.7964C1.33337 16.9205 0.791485 19.0447 2.08915 19.0447C4.68448 19.0447 9.47585 10.5481 12.0712 10.5481C14.6665 10.5481 9.88226 19.0447 12.4847 19.0447C15.0872 19.0447 19.8714 10.5481 22.4739 10.5481C25.0763 10.5481 20.285 19.0447 22.8874 19.0447C23.4578 19.0447 24.1423 18.6326 24.8767 17.9861"
      stroke="#8DA4EF"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default PenIllustration;
