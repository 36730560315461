const CloseIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    viewBox="0 0 10 10"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <path
      d="M9 1L1 9M1 1L9 9"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseIcon;
