import { useCallback } from "react";
import { resetAllStores, useAppStore } from "../store";
import {
  switchOrganisation,
  updateInviteSecretAPI,
} from "@/app/services/organisation";
import { StructOrganisation } from "@/app/types/Organisation.type";
import { removeAllEvents } from "../utils/events";
import { useRouter } from "next/navigation";

const useOrganisation = () => {
  const organisationId = useAppStore((state) => state.organisation.id);
  const setOrganisation = useAppStore((state) => state.setOrganisation);
  const router = useRouter();

  const updateInviteSecret =
    useCallback(async (): Promise<StructOrganisation> => {
      try {
        const secret = await updateInviteSecretAPI();
        return secret;
      } catch (error) {
        console.log(error);
        throw error;
      }
    }, [organisationId]);

  const handleSwitchOrganisation = async (organisation: StructOrganisation) => {
    try {
      const response = await switchOrganisation({
        organisationId: organisation.id,
      });
      if (response.ok) {
        resetAllStores();
        removeAllEvents();
        setOrganisation(organisation);
        router.push("/");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return {
    updateInviteSecret,
    handleSwitchOrganisation,
  };
};

export default useOrganisation;
