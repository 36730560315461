import { useState, useEffect } from "react";
import { Button, Flex } from "@radix-ui/themes";
import NameEmojiStep from "./NameEmojiStep";
import RefineFiltersStep from "./RefineFiltersStep";
import { cloneDeep, isEqual, isEmpty } from "lodash";
import { FeedFilter } from "@/app/types/FeedFitler.type";
import { CreateFeedPayload, StructFeed } from "@/app/types/Feed.type";
import { StructChannel } from "@/app/types/Channel.type";
import { StructUser } from "@/app/types/Thread.type";
import { EntityIDMap } from "@/app/constants";

type Kind = "any_of" | "all_of" | "none_of";

interface FilterRow {
  kind: string;
  values: string[];
}

interface LocalFiltersState {
  tags?: FilterRow[];
  ids?: FilterRow[];
  bits: CreateFeedPayload["bits"];
}
const INITIAL_LOCAL_FILTERS_STATE = {
  tags: [{ kind: "any_of", values: [] }],
  ids: [{ kind: "any_of", values: [] }],
  bits: { feed_dm: false, feed_unread: false, thread_bookmarked: false },
};

interface CreateEditFeedDialogProps {
  handleClose: () => void;
  onSubmit: ({
    feedName,
    emoji,
    filters,
    bits,
  }: {
    feedName: string;
    emoji: string;
    filters: FeedFilter[];
    bits: CreateFeedPayload["bits"];
  }) => void;
  localFilters: FeedFilter[];
  feedName?: string;
  emoji?: string;
  bits?: CreateFeedPayload["bits"];
  entityMap: StructFeed["entity_map"];
}

const CreateEditFeedDialog = ({
  handleClose,
  onSubmit,
  localFilters,
  feedName,
  emoji,
  bits,
  entityMap,
}: CreateEditFeedDialogProps) => {
  const [name, setFeedName] = useState(feedName ?? "");
  const [selectedEmoji, setSelectedEmoji] = useState(emoji ?? "");

  const [localFiltersState, setLocalFiltersState] = useState<LocalFiltersState>(
    cloneDeep(INITIAL_LOCAL_FILTERS_STATE),
  );

  const updateLocalFiltersState = (newState: any) =>
    setLocalFiltersState((state) => ({ ...state, ...newState }));

  useEffect(() => {
    if (isEmpty(localFilters)) {
      return;
    }
    const newFilters = { tags: [], ids: [], bits } as LocalFiltersState;

    for (let index = 0; index < localFilters.length; index++) {
      const filter = localFilters[index];
      if (!isEmpty(filter?.tags)) {
        newFilters?.tags?.push({
          kind: filter.kind,
          values: filter.tags as string[],
        });
      }

      if (!isEmpty(filter?.ids)) {
        newFilters?.ids?.push({
          kind: filter.kind,
          values: filter.ids as string[],
        });
      }
    }

    // Add default values if the filter is empty
    if (isEmpty(newFilters.tags)) {
      newFilters.tags = INITIAL_LOCAL_FILTERS_STATE.tags;
    }
    // Add default values if the filter is empty
    if (isEmpty(newFilters.ids)) {
      newFilters.ids = INITIAL_LOCAL_FILTERS_STATE.ids;
    }

    updateLocalFiltersState(newFilters);
  }, [localFilters]);

  const handleSave = () => {
    const filters = [] as FeedFilter[];
    const { tags, ids } = localFiltersState;
    if (tags) {
      for (let index = 0; index < tags.length; index++) {
        if (tags[index].values.length > 0) {
          const filter = {
            kind: tags[index].kind as Kind,
            tags: tags[index].values,
            ids: [],
          };
          filters.push(filter);
        }
      }
    }
    if (ids) {
      for (let index = 0; index < ids.length; index++) {
        if (ids[index].values.length > 0) {
          const filter = {
            kind: ids[index].kind as Kind,
            ids: ids[index].values,
            tags: [],
          };
          filters.push(filter);
        }
      }
    }

    onSubmit({
      feedName: name,
      emoji: selectedEmoji,
      filters,
      bits: localFiltersState.bits,
    });
  };

  return (
    <Flex direction="column" gap="3">
      <NameEmojiStep
        name={name}
        setFeedName={setFeedName}
        emoji={selectedEmoji}
        setSelectedEmoji={setSelectedEmoji}
      />

      <Flex mt="3">
        <RefineFiltersStep
          localFiltersState={localFiltersState}
          updateLocalFiltersState={updateLocalFiltersState}
          entityMap={entityMap}
        />
      </Flex>

      <Flex direction="row" gap="2" align="center" justify="end">
        <Button
          radius="small"
          size="3"
          variant="soft"
          color="gray"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          radius="small"
          size="3"
          variant="solid"
          onClick={handleSave}
          disabled={
            !(
              Boolean(name) &&
              !isEqual(localFiltersState, INITIAL_LOCAL_FILTERS_STATE)
            )
          }
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

export default CreateEditFeedDialog;
