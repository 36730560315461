import { Flex, IconButton, Text } from "@radix-ui/themes";
import React from "react";
import { ArrowLeftIcon, HamburgerMenuIcon } from "@radix-ui/react-icons";
import useDesktopMode from "@/app/hooks/useDesktopMode";
import useShallowNavigation from "@/app/hooks/useShallowNavigation";

const HEADER_HEIGHT = "42px";

interface Props {
  handleOpen: () => void;
  label?: string;
}

const MobileHeader = ({ handleOpen, label }: Props) => {
  const { isDesktop } = useDesktopMode();
  const { currentPath, navigateToPath } = useShallowNavigation();
  if (isDesktop) return null;

  if (currentPath) {
    return (
      <Flex gap="2" align="center" pl="2" pt="2">
        <IconButton
          style={{
            background: "var(--white-1)",
          }}
          variant="ghost"
          onClick={() => navigateToPath(null)}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Text size="2" style={{ opacity: 0.3 }}>
          {label}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex
      style={{
        position: "sticky",
        width: "100%",
        borderRadius: "0px 0px var(--space-3) var(--space-3)",
        borderBottom: "1px solid var(--slate-a4)",
        background: "var(--slate-2)",
        padding: "var(--space-1) 0",
        zIndex: 1,
        minHeight: HEADER_HEIGHT,
        top: 0,
      }}
      align="center"
      justify="center"
      display={{
        initial: "flex",
        xs: "flex",
        md: "flex",
      }}
    >
      <IconButton
        style={{
          background: "var(--white-1)",
          position: "absolute",
          left: 0,
        }}
        variant="outline"
        onClick={handleOpen}
      >
        <HamburgerMenuIcon />
      </IconButton>
      <Text weight="medium" align="center">
        {label}
      </Text>
    </Flex>
  );
};

export default MobileHeader;
