import React, { useRef, useEffect } from "react";
import styles from "./RealtimeIndicator.module.scss";
import { Flex, Text } from "@radix-ui/themes";
import events from "@/app/utils/events";
import { WS_PING_RECEIVED } from "@/app/eventConstants";
import { deviceId } from "@/app/utils/sessionStorage";
import { trackEvent } from "@/app/utils/tracking";
import { useAppStore } from "@/app/store";
import { APP_VERSION, DESKTOP_APP_VERSION } from "@/app/constants";

const REFRESH_ICON_INTERVAL = 1000 * 60 * 5; // 5 minutes

const RealtimeIndicator = () => {
  const dotRef = useRef<HTMLDivElement | null>(null);
  const labelRef = useRef<HTMLElement | null>(null);
  const setShouldResync = useAppStore((state) => state.setShouldResync);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handlePing = () => {
    resetTimer();
    dotRef.current?.classList.remove(styles.wsStatusDot);
    dotRef.current?.scrollBy(0, 0);
    dotRef.current?.classList.add(styles.wsStatusDot);

    labelRef.current?.classList.remove(styles.wsStatusLabel);
    labelRef.current?.scrollBy(0, 0);
    labelRef.current?.classList.add(styles.wsStatusLabel);
  };

  const resetTimer = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setShouldResync(true);
      trackEvent("ws_disconnected", { deviceId });
    }, REFRESH_ICON_INTERVAL);
  };
  useEffect(() => {
    resetTimer();
    events.on(WS_PING_RECEIVED, handlePing);
    return () => {
      events.off(WS_PING_RECEIVED, handlePing);
    };
  }, []);

  const title = deviceId + " | v" + APP_VERSION + " | dv" + DESKTOP_APP_VERSION;

  return (
    <Flex align="center" gap="1" title={title}>
      <div ref={dotRef} className={` ${styles.wsStatusDot}`} />
      <Text ref={labelRef} size="1" className={`${styles.wsStatusLabel}  `} />
    </Flex>
  );
};

export default RealtimeIndicator;
