import React from "react";
import {
  ChatBubbleIcon,
  ClockIcon,
  DotFilledIcon,
  EnvelopeClosedIcon,
  FileIcon,
} from "@radix-ui/react-icons";
import { Avatar, Button, Flex, Text } from "@radix-ui/themes";
import { useRouter } from "next/navigation";

import { getAvatarImageURL } from "@/app/utils/imageUtils";
import { StructUser } from "@/app/types/Thread.type";
import { useAppStore } from "@/app/store";
import styles from "./ProfileDetails.module.scss";

const getTime = (timeZone: string) => {
  const optionsTime = {
    timeZone,
    hour: "numeric",
    minute: "numeric",
  } as const;

  const optionsTimeZone = {
    timeZone,
    timeZoneName: "short",
  } as const;

  const current = new Date();
  const tz = current.toLocaleTimeString([], optionsTimeZone).split(" ")[2];

  return `${current.toLocaleTimeString([], optionsTime)} local time, ${tz}`;
};

export const GuestProfile = ({ user }: { user?: StructUser }) => {
  const onlineUserIds = useAppStore((state) => state.onlineUserIds);
  const setSearchQuery = useAppStore((state) => state.setSearchQuery);

  const router = useRouter();

  if (!user) {
    return null;
  }
  const isOnline = onlineUserIds.includes(user.id);

  const showThreads = () => {
    setSearchQuery(`<@${user.id}>`);
    router.push("/");
  };

  return (
    <Flex
      direction="row"
      width="100%"
      height="100%"
      p="8"
      align="center"
      justify="center"
    >
      <Flex direction="column" className={styles.halfColumn} mt="3">
        <Avatar
          src={getAvatarImageURL(user?.["avatar_id"] || "", 128)}
          className={styles.profilePic}
          fallback={user?.["display_name"] || ""}
          radius="large"
        />
        {isOnline && (
          <div
            style={{
              height: "var(--space-6)",
              width: "var(--space-6)",
              borderRadius: "var(--radius-2)",
              border: "1.5px solid var(--slate-9-contrast)",
              background: "var(--blue-9)",
              bottom: "32px",
              right: "-128px",
              position: "relative",
            }}
          />
        )}
        <Flex gap="2" mt="7" direction="column">
          <Text weight="bold" size="2">
            Details
          </Text>
          {isOnline && (
            <Flex direction="row" gap="1" align="center">
              <DotFilledIcon />
              Online
            </Flex>
          )}
          <Flex direction="row" gap="1" align="center">
            <ClockIcon />
            {getTime(user["time_zone"] || "America/Los Angeles")}
          </Flex>
          <Flex direction="row" gap="1" align="center">
            <EnvelopeClosedIcon />
            {user["email_id"]}
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="column" className={styles.halfColumn} mt="3">
        <Text size="6">{user["real_name"]}</Text>
        <Text size="6" color="blue">
          @{user["display_name"]}
        </Text>

        <Text weight="bold" mt="7">
          About
        </Text>
        <Text mt="1">{user["about_me"]}</Text>
        <Flex direction="column" gap="1" mt="7">
          <Button
            variant="solid"
            color="blue"
            radius="large"
            className={styles.messageBtn}
          >
            <ChatBubbleIcon />
            Message {user["real_name"]}
          </Button>
          <Button
            variant="outline"
            radius="large"
            className={styles.threadsBtn}
            onClick={showThreads}
          >
            <FileIcon />
            See {user["real_name"]}&apos;s threads
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
