import { useRef, useState } from "react";
import { debounce } from "lodash";
import { getSearchResultsAPI } from "../services/search";
import { getConvertedSearchResults } from "../components/CommandMenu/utils";
import { globalStore } from "../store";
import { SearchDocument } from "./../types/Search.type";

const useSearch = () => {
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState<SearchDocument[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const debouncedSearch = useRef(
    debounce(async (query, sorting) => {
      try {
        if (!query) return;
        setIsSearching(true);
        setResults([]);
        const feedTimeRange = globalStore.getState().feedTimeRange;
        const { hits, formattedText } = await getSearchResultsAPI({
          q: query,
          sortBy: sorting,
          from: feedTimeRange ? Date.now() - feedTimeRange : undefined,
          to: feedTimeRange ? Date.now() : undefined,
        });

        setSearchQuery(formattedText);

        if (!hits || hits.length <= 0) {
          setResults([]);
          setIsSearching(false);
          return;
        }

        const convertedSearchResults = getConvertedSearchResults(hits);
        setResults(convertedSearchResults);
      } catch (error) {
        console.error(error);
      }

      setIsSearching(false);
    }, 600),
  ).current;

  return {
    isSearching,
    debouncedSearch,
    results,
    setResults,
    searchQuery,
  };
};

export default useSearch;
