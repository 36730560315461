import { useState } from "react";
import { SelectedFile } from "../components/ChatBox/FilePreview";
import useFiles from "./useFiles";

interface UseFileUploadProps {
  isSingleSelect?: boolean;
}

export const useFileUpload = (props: UseFileUploadProps) => {
  const { isSingleSelect } = props;
  const [selectedFiles, setSelectedFiles] = useState<Array<SelectedFile>>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);

  const { uploadFile } = useFiles();

  const handleFiles = async (files: File[]) => {
    const previewImages = files.map((file) => {
      const reader = new FileReader();
      const readerPromise = new Promise<{ file: File; url: string }>(
        (resolve) => {
          reader.readAsDataURL(file);
          reader.onload = () => {
            resolve({ file, url: reader.result as string });
          };
        },
      );
      return readerPromise;
    });

    const loadedPreviews = await Promise.all(previewImages);

    if (isSingleSelect) {
      setSelectedFiles([
        ...loadedPreviews.map((preview) => ({ ...preview, isLoading: true })),
      ]);
    } else {
      setSelectedFiles((prevFiles) => [
        ...prevFiles,
        ...loadedPreviews.map((preview) => ({ ...preview, isLoading: true })),
      ]);
    }

    const uploadPromises = files.map((file) =>
      uploadFile(file)
        .then((uploadedFile) => uploadedFile?.handle)
        .catch((error) => {
          console.error("Error uploading file:", error);
          return null;
        }),
    );

    const fileUrls = (await Promise.all(uploadPromises)).filter(
      Boolean,
    ) as string[];

    setFileUrls(fileUrls);

    if (isSingleSelect) {
      setSelectedFiles([
        ...loadedPreviews.map((preview) => ({ ...preview, isLoading: false })),
      ]);
    } else {
      setSelectedFiles((prevFiles) =>
        prevFiles.map((file) => {
          if (files.includes(file.file)) {
            return { ...file, isLoading: false };
          }
          return file;
        }),
      );
    }
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);
      await handleFiles(files);
    }
  };

  return {
    selectedFiles,
    fileUrls,
    handleFileSelect,
  };
};
