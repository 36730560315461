import styles from "./Tag.module.scss";
import { Text } from "@radix-ui/themes";
import CloseIcon from "./icons/CloseIcon";

interface TagProps {
  label: string | number;
  onRemove?: () => void;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  endIcon?: React.ElementType;
  startIcon?: React.ElementType;
  size?: "small" | "medium";
}

const Tag = ({
  label,
  className,
  style,
  onRemove,
  onClick,
  endIcon,
  startIcon,
  size = "medium",
}: TagProps) => {
  const EndIcon = endIcon;
  const StartIcon = startIcon;

  const handleRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (!onRemove) {
      return;
    }

    onRemove();
  };

  const labelSize = size === "small" ? "1" : "2";

  return (
    <div
      onClick={onClick}
      style={style}
      className={[
        styles.tag,
        className,
        onClick ? styles.isInteractive : null,
      ].join(" ")}
    >
      {StartIcon && <StartIcon className={styles.startIcon} />}
      <Text size={labelSize} weight="medium" className={styles.label}>
        {label}
      </Text>

      {EndIcon && <EndIcon className={styles.endIcon} />}
      {onRemove && (
        <div className={styles.closeButtonContainer}>
          <button className={styles.closeButton} onClick={handleRemove}>
            <CloseIcon className={styles.closeButtonIcon} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Tag;
