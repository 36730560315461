import posthog from "posthog-js";
import { Userpilot } from "userpilot";

import { isProduction, EXCLUDE_TRACKING_ORG_IDS } from "../constants";

const initPostHog = (
  userId?: string,
  email?: string,
  userName?: string,
  orgId?: string,
) => {
  if (EXCLUDE_TRACKING_ORG_IDS.includes(orgId || "")) return;
  if (!userId) {
    userId = "anon@anon.who";
  }
  posthog.init("phc_lVqMO1scP0j1yB8eCM0VsGJzoxCjyG8Jj3KvGRba6yd", {
    api_host: "https://app.posthog.com",
  });
  posthog.identify(userId, {
    email_id: email,
    user_id: userId,
    name: userName,
    org_id: orgId,
  });
};

const initUserpilot = (
  userId?: string,
  userName?: string,
  email?: string,
  orgId?: string,
) => {
  if (!userId || !userName || !email || !orgId) return;
  Userpilot.initialize("NX-4cd86f9b");
  return Userpilot.identify(userId, {
    name: userName,
    email: email,
    org_id: orgId,
  });
};

export const reloadUserPilot = () => {
  if (!isProduction) return;
  Userpilot.reload();
};

export const initTracking = (
  userId?: string,
  userName?: string,
  email?: string,
  orgId?: string,
) => {
  if (!isProduction) return;
  initPostHog(userId, email, userName, orgId);
  initUserpilot(userId, userName, email, orgId);
};

export const trackEvent = (event: string, properties?: any) => {
  console.info("event :", event, properties);
  if (!isProduction) return;
  posthog.capture(event, properties);
};
