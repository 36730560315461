"use client";

import React, { useEffect, useState, useRef } from "react";

import { useAppStore } from "../store";

import NotificationContainer from "./NotificationContainer";
import CommandMenu from "../components/CommandMenu/CommandMenuV2";
import { getOrgDetails } from "../services/organisation";
import { getAllTags } from "../services/tags";
import { getFeedsAPI } from "../services/feeds";

import { StructFeed } from "@/app/types/Feed.type";

import getSession from "../utils/getSession";

import { getLocalStorageState } from "@/app/utils/localStorage";
import { Flex } from "@radix-ui/themes";
import { StructOrganisation } from "../types/Organisation.type";
import { useApp } from "../hooks/useApp";
import { FeedModes } from "../store/App.store";
import Sidebar from "@/app/components/SideBar/Sidebar";
import { isApp } from "../constants";
import useOrganisation from "../hooks/useOrganisation";
import { initTracking, reloadUserPilot } from "../utils/tracking";
import { usePathname } from "next/navigation";
import useShallowNavigation from "../hooks/useShallowNavigation";
import { usePresence } from "../hooks/usePresence";
import useRealtimeEvents from "../hooks/useServerSentEvents";

interface LayoutContainerProps {
  children: React.ReactNode;
}

const LayoutContainer = ({ children }: LayoutContainerProps) => {
  const [isLoaded, setLoaded] = useState(false);
  const localState = getLocalStorageState();
  const { handleSwitchOrganisation } = useOrganisation();

  const { featureToggles } = useApp();

  const olderSessionOrgId = useRef("");

  const organisation = useAppStore((state) => state.organisation);
  const setOrganisation = useAppStore((state) => state.setOrganisation);
  const setTags = useAppStore((state) => state.setTags);
  const setFeeds = useAppStore((state) => state.setFeeds);
  const setSession = useAppStore((state) => state.setSession);

  const currentSession = useAppStore((state) => state.session);

  const pathname = usePathname();
  const { currentPath } = useShallowNavigation();

  const setFeedMode = useAppStore((state) => state.setFeedMode);
  const isAuthenticatedWithCorrectOrg = useAppStore(
    (state) => state.isAuthenticatedWithCorrectOrg,
  );

  const organisationId = organisation.id;

  usePresence();

  const { initializeRealtimeConnection } = useRealtimeEvents();

  const init = async () => {
    // This logic helps prevent multiple calls to the API when org is set for the first time
    if (olderSessionOrgId.current === organisationId) return;
    setLoaded(false);
    const session = await getSession();
    const isAuthenticatedWithCorrectOrg = Boolean(session?.user?.id);

    // Auto switch organisation, if user is part of multiple organisations
    if (!session?.user && (session?.orgs?.length ?? 0) > 0 && isApp) {
      await handleSwitchOrganisation(session?.orgs[0] as StructOrganisation);
      return;
    }

    const [organisation, tags] = await Promise.all([
      getOrgDetails(),
      getAllTags(),
    ]);

    const feeds: StructFeed[] | null = isAuthenticatedWithCorrectOrg
      ? await getFeedsAPI()
      : null;

    if (organisation) {
      setOrganisation(organisation);
      olderSessionOrgId.current = organisation.id;
    }

    if (tags) {
      setTags([...tags]);
    }

    if (feeds) {
      setFeeds([...feeds]);
    }

    if (session) {
      setSession({ ...session });
    }

    setLoaded(true);
  };

  const initializeFeedLayout = () => {
    const localStateFeedMode = localState.feedMode;

    if (localStateFeedMode === undefined) {
      return;
    }
    if (featureToggles.isLiveViewEnabled) {
      setFeedMode(localStateFeedMode);
      return;
    }

    if (localStateFeedMode === FeedModes.LIVE) {
      setFeedMode(FeedModes.FORUM);
      return;
    }
  };

  useEffect(() => {
    init();
  }, [organisationId]);

  useEffect(() => {
    initializeFeedLayout();
  }, [featureToggles?.isLiveViewEnabled, organisationId]);

  useEffect(() => {
    if (!currentSession?.email || !organisationId) {
      return;
    }

    initTracking(
      currentSession?.user?.id,
      currentSession?.user?.display_name,
      currentSession?.email,
      organisationId,
    );
  }, [currentSession?.email, organisationId]);

  useEffect(() => {
    reloadUserPilot();
  }, [pathname, currentPath]);

  useEffect(() => {
    if (!isAuthenticatedWithCorrectOrg) return;
    initializeRealtimeConnection();
  }, [isAuthenticatedWithCorrectOrg]);

  if (!isLoaded) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <NotificationContainer key={organisationId}>
      <CommandMenu />
      <Flex
        style={{ height: "100vh", overflow: "clip" }}
        direction={{ initial: "column", sm: "row" }}
        key={organisationId}
      >
        <Sidebar />
        {children}
      </Flex>
    </NotificationContainer>
  );
};

export default LayoutContainer;
