import React, { useState } from "react";
import Toggle from "../Toggle";
import { SpeakerLoudIcon, SpeakerOffIcon } from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import {
  getLocalStorageState,
  setLocalStorageState,
} from "@/app/utils/localStorage";

const NotificationToggle = () => {
  const localState = getLocalStorageState();
  const { isNotificationEnabled = true } = localState;
  const [isEnabled, setIsEnabled] = useState<boolean>(isNotificationEnabled);

  const handleChange = () => {
    setLocalStorageState({ isNotificationEnabled: !isNotificationEnabled });
    setIsEnabled((isEnabled) => !isEnabled);
  };

  return (
    <Toggle
      selectedIndex={isEnabled ? 1 : 0}
      onCheckedChange={handleChange}
      items={[
        {
          description: "Disable Notifications",
          icon: (
            <Flex ml="1">
              <SpeakerOffIcon />
            </Flex>
          ),
        },
        {
          description: "Enable Notifications",
          icon: (
            <Flex mr="1">
              <SpeakerLoudIcon />
            </Flex>
          ),
        },
      ]}
    />
  );
};

export default NotificationToggle;
