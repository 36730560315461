import { useAppStore, globalStore } from "@/app/store";
import {
  Read,
  StructChat,
  StructChatResponse,
  StructThread,
  StructThreadResponse,
  StructUser,
} from "@/app/types/Thread.type";
import { useCallback, useEffect, useRef } from "react";

import { DEFAULT_FEED_ID } from "@/app/store/App.store";
import { joinThreadData } from "@/app/utils/dataUtils";
import { getAvatarImageURL } from "@/app/utils/imageUtils";
import isArray from "lodash/isArray";
import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import { StructFeed } from "../types/Feed.type";
import { StructOrganisation } from "@/app/types/Organisation.type";
import events from "../utils/events";
import {
  REALTIME_RECONNECTION_TRIGGERED,
  REMOVE_FROM_FEED_KEEP_THREAD_OPEN,
  TRIGGER_NOTIFICATION,
  WS_PING_RECEIVED,
} from "../eventConstants";
import {
  APP_VERSION,
  DESKTOP_APP_VERSION,
  STRUCT_API_URL,
  STRUCT_API_VERSION,
} from "../constants";
import { deviceId } from "../utils/sessionStorage";
import useShallowNavigation from "./useShallowNavigation";
import { trackEvent } from "../utils/tracking";
import useDebounce from "./useDebounce";

enum RealtimeMessagePath {
  TYPING = "/typing",
  SERVER_UPDATE = "server/update",
  PRESENCE = "/presence",
  FEED = "/feed",
  SERVER_FEED = "server/feed",
  PING = "/ping",
  PONG = "/pong",
  VERSION = "/version",
}

enum MessageType {
  CHAT = "chats",
  THREAD = "threads",
  REACTION = "reactions",
  READ = "reads",
  FEEDS = "feeds",
  CHANNELS = "channels",
  USERS = "users",
  ORGS = "orgs",
}

enum MessageAction {
  INSERT = "insert",
  UPDATE = "update",
  DELETE = "delete",
  MOVE_TO_TOP = "move_to_top",
  REMOVE_FROM_FEED = "remove_from_feed",
  NUM_UNREAD = "num_unread",
}

enum PresenceStatus {
  ACTIVE = "active",
  AWAY = "away",
}

const RECONNECTION_DELAY = 10000;
const USER_TYPING_CLEAR_TIMEOUT = 6000;
const REFRESH_RECONNECTION_INTERVAL = 30 * 60 * 1000; // 30 minutes

const DEFAULT_NOTIFICATION_MESSAGE = "New chat message in Struct";

const useRealtimeEvents = () => {
  const updateChatMessage = useAppStore((state) => state.updateChatMessage);
  const setChatMessage = useAppStore((state) => state.setChatMessage);
  const setChatReaction = useAppStore((state) => state.setChatReaction);
  const deleteChatReaction = useAppStore((state) => state.deleteChatReaction);
  const deleteChatMessage = useAppStore((state) => state.deleteChatMessage);
  const setBulkReadUntilByThreadId = useAppStore(
    (state) => state.setBulkReadUntilByThreadId,
  );
  const removeThreadChatById = useAppStore(
    (state) => state.removeThreadChatById,
  );
  const updateThreadChatById = useAppStore(
    (state) => state.updateThreadChatById,
  );
  const addUserTyping = useAppStore((state) => state.addUserTyping);
  const removeUserTyping = useAppStore((state) => state.removeUserTyping);
  const setThreadById = useAppStore((state) => state.setThreadById);
  const deleteThreads = useAppStore((state) => state.deleteThreads);
  const insertThreadChatById = useAppStore(
    (state) => state.insertThreadChatById,
  );
  const updateThreadsById = useAppStore((state) => state.updateThreadsById);
  const addOnlineUserId = useAppStore((state) => state.addOnlineUserId);
  const removeOnlineUserId = useAppStore((state) => state.removeOnlineUserId);
  const getThreadsById = useAppStore((state) => state.getThreadsById);
  const moveThreadToTop = useAppStore((state) => state.moveThreadToTop);
  const setFeed = useAppStore((state) => state.setFeed);
  const updateFeed = useAppStore((state) => state.updateFeed);
  const session = useAppStore((state) => state.session);
  const organisation = useAppStore((state) => state.organisation);
  const updateOrganisation = useAppStore((state) => state.updateOrganisation);

  const deleteStoreFeed = useAppStore((state) => state.deleteStoreFeed);
  const setCurrentFeedId = useAppStore((state) => state.setCurrentFeedId);


  const setUnreadThreadCountByFeedId = useAppStore(
    (state) => state.setUnreadThreadCountByFeedId,
  );

  const updateSessionUser = useAppStore((state) => state.updateSessionUser);

  const setShouldResync = useAppStore((state) => state.setShouldResync);
  const setShouldUpgrade = useAppStore((state) => state.setShouldUpgrade);

  const setIsRealtimeConnectionActive = useAppStore(
    (state) => state.setIsRealtimeConnectionActive,
  );
  const setOpenThreadById = useAppStore((state) => state.setOpenThreadById);

  const { getCurrentPath } = useShallowNavigation();

  const hasPriorConnectionEstablished = useRef(false);

  const sseInstanceRef = useRef<EventSource | null>(null);
  const userTimeoutsRef = useRef<Record<string, Record<string, number>>>({});
  const pingTimeoutRef = useRef<number | null>(null);
  const lastPingTimestamp = useRef<number | null>(null);
  const queueReadActionsRef = useRef<
    {
      action: MessageAction;
      data: StructChatResponse;
      threadId: StructThread["id"];
    }[]
  >([]);

  useEffect(() => {
    const handleOffline = () => {
      if (!sseInstanceRef.current) {
        return;
      }

      sseInstanceRef.current?.close();
      sseInstanceRef.current = null;
      setIsRealtimeConnectionActive(false);
    };

    const handleOnline = () => {
      initializeServerSentEvents();
    };

    const handleVisibilityChange = () => {
      if (!sseInstanceRef.current) {
        initializeServerSentEvents();
      }
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      // close realtime connection      
      sseInstanceRef.current?.close();
    };
  }, []);

  const handleOpen = useCallback(() => {
    if (!sseInstanceRef.current) return;
    setIsRealtimeConnectionActive(true);
    notifyReconnectionToApp();
    console.info("Connected to the Realtime server ", deviceId);
    if (!hasPriorConnectionEstablished.current) {
      hasPriorConnectionEstablished.current = true;
    }
  }, []);

  const handleTypingMessage = (
    {
      user_id,
      user_name,
    }: { user_id: StructUser["id"]; user_name: StructUser["real_name"] },
    threadId: StructThread["id"],
  ) => {
    if (!userTimeoutsRef.current[threadId]) {
      userTimeoutsRef.current[threadId] = {};
    }

    if (userTimeoutsRef.current[threadId][user_id]) {
      clearTimeout(userTimeoutsRef.current[threadId][user_id]);
    }

    userTimeoutsRef.current[threadId][user_id] = window.setTimeout(() => {
      removeUserTyping(threadId, user_id);
      delete userTimeoutsRef.current[threadId][user_id];
      if (Object.keys(userTimeoutsRef.current[threadId]).length === 0) {
        delete userTimeoutsRef.current[threadId];
      }
    }, USER_TYPING_CLEAR_TIMEOUT);

    addUserTyping(threadId, user_id, user_name);
  };

  const handleServerUpdateMessage = ({
    type,
    action,
    data,
    threadId,
  }: {
    type: MessageType;
    action: MessageAction;
    data: any;
    threadId: StructThread["id"];
  }) => {
    if (type === MessageType.CHAT) {
      handleChatMessage({ action, data, threadId });
      return;
    }

    if (type === MessageType.THREAD) {
      handleThreadMessage({ action, data, threadId });
      return;
    }

    if (type === MessageType.REACTION) {
      handleReactionMessage({ action, data, threadId });
      return;
    }

    if (type === MessageType.READ) {
      queueReadActionsRef.current.push({ action, data, threadId });
      // @ts-expect-error
      debouncedHandleReadMessage();
      return;
    }
    if (type === MessageType.FEEDS) {
      handleFeedsMessage({ action, data });
    }

    if (type === MessageType.USERS) {
      handleUsersMessage({ action, data });
    }

    if (type === MessageType.ORGS) {
      handleOrgsMessage({ action, data });
    }
  };

  const handleServerFeedMessage = async ({
    data,
    action,
    threadId,
  }: {
    data: any;
    action: MessageAction;
    threadId: StructThread["id"];
  }) => {
    if (action === MessageAction.MOVE_TO_TOP) {
      const threadsById = getThreadsById();
      const existingThread = threadsById[threadId];

      const thread = merge(existingThread, data as StructThreadResponse);
      const transformedThread: StructThread = joinThreadData({
        thread,
        organisation,
      });

      setThreadById(transformedThread);
      moveThreadToTop(threadId);
      return;
    }

    if (action === MessageAction.REMOVE_FROM_FEED) {
      const threadsById = getThreadsById();
      const existingThread = threadsById[threadId];
      if (!existingThread) {
        return;
      }

      const currentlyOpenThreadId = await getCurrentPath();
      if (threadId === currentlyOpenThreadId) {
        // The following will delete the thread from the feed but keep the thread open only when thread container is mounted.
        events.emit(REMOVE_FROM_FEED_KEEP_THREAD_OPEN, {
          thread: existingThread,
        });
        return;
      }

      deleteThreads([threadId]);
      return;
    }

    if (action === MessageAction.NUM_UNREAD) {
      const { feed_id, num_unread } = data;
      setUnreadThreadCountByFeedId(feed_id, num_unread);
    }
  };

  const handleReadMessage = () => {
    try {
      const threadUpdatesById = {} as Record<string, any>;

      const chatUpdatesByThreadId = {} as Record<string, string>;
      for (let index = 0; index < queueReadActionsRef.current.length; index++) {
        const { action, data, threadId } = queueReadActionsRef.current[index];

        if (action === MessageAction.UPDATE) {
          const { num_unread, chat_id, bits } = data as Partial<Read>;

          if (num_unread || num_unread === 0 || bits) {
            threadUpdatesById[threadId] = {
              read: {
                num_unread: num_unread,
                bits: bits,
              },
            };
          }

          if (threadId && chat_id) {
            chatUpdatesByThreadId[threadId] = chat_id;
          }
        }

        if (action === MessageAction.INSERT) {
          const { num_unread, chat_id, bits } = data as Partial<Read>;
          if (num_unread || num_unread === 0 || bits) {
            threadUpdatesById[threadId] = {
              read: {
                num_unread: num_unread,
                bits: bits,
              },
            };
          }

          if (threadId && chat_id) {
            chatUpdatesByThreadId[threadId] = chat_id;
          }

          if (chat_id) {
            threadUpdatesById[threadId] = {
              read: {
                chat_id,
              },
            };
          }
        }
      }

      // Do bulk updates

      updateThreadsById(threadUpdatesById);
      setBulkReadUntilByThreadId(chatUpdatesByThreadId);
    } catch (error) {
      console.error("error :", error);
    }
    queueReadActionsRef.current = [];
    return;
  };
  const debouncedHandleReadMessage = useDebounce(handleReadMessage, 1000);

  const handleUsersMessage = ({
    action,
    data: user,
  }: {
    action: MessageAction;
    data: StructUser;
  }) => {
    if (session?.user?.id === user.id && action === MessageAction.UPDATE) {
      updateSessionUser(user);
    }
  };

  const handleOrgsMessage = ({
    action,
    data: organisation,
  }: {
    action: MessageAction;
    data: StructOrganisation;
  }) => {
    if (action === MessageAction.UPDATE) {
      updateOrganisation(organisation);
    }
  };

  const handleFeedsMessage = ({
    action,
    data,
  }: {
    action: MessageAction;
    data: StructFeed;
  }) => {
    if (action === MessageAction.INSERT) {
      setFeed(data);
    }
    if (action === MessageAction.UPDATE) {
      updateFeed(data);
    }
    if (action === MessageAction.DELETE) {
      deleteStoreFeed(data.id);
      if (globalStore.getState().currentFeedId === data.id) {
        setCurrentFeedId(DEFAULT_FEED_ID);
      }
    }
  };

  const handleShowBrowserNotification = (chatMessage: StructChatResponse) => {
    const isLoggedInUserAuthor = chatMessage.author_id === session?.user?.id;

    if (isLoggedInUserAuthor) {
      return;
    }

    const threadsById = getThreadsById();

    const thread = threadsById[chatMessage.thread_id];
    const notificationTitle = thread
      ? thread?.subject_plain ?? DEFAULT_NOTIFICATION_MESSAGE
      : DEFAULT_NOTIFICATION_MESSAGE;

    const notificationMessage = `${chatMessage.text_plain} - @${chatMessage.author.display_name}`;
    const isMentioned = session?.user?.id
      ? chatMessage.text.includes(session?.user?.id)
      : false;

    const avatarId = chatMessage.author.avatar_id;

    events.emit(TRIGGER_NOTIFICATION, {
      title: notificationTitle,
      options: { body: notificationMessage, icon: getAvatarImageURL(avatarId) },
      threadId: chatMessage.thread_id,
      isMentioned: isMentioned,
    });
  };

  const handleChatMessage = ({
    action,
    data,
    threadId,
  }: {
    action: MessageAction;
    data: StructChatResponse;
    threadId: StructThread["id"];
  }) => {
    const chatMessage = data as StructChatResponse;
    const { author_id } = chatMessage;

    if (
      userTimeoutsRef.current[threadId] &&
      userTimeoutsRef.current[threadId][author_id]
    ) {
      clearTimeout(userTimeoutsRef.current[threadId][author_id]);
      delete userTimeoutsRef.current[threadId][author_id];
      if (Object.keys(userTimeoutsRef.current[threadId]).length === 0) {
        delete userTimeoutsRef.current[threadId];
      }
      removeUserTyping(threadId, author_id);
    }

    if (action === MessageAction.INSERT) {
      setChatMessage(threadId, chatMessage);
      insertThreadChatById(threadId, chatMessage);

      handleShowBrowserNotification(chatMessage);
      return;
    }

    if (action === MessageAction.DELETE) {
      removeThreadChatById(threadId, chatMessage.id);
      deleteChatMessage(threadId, chatMessage.id);
      return;
    }

    if (action === MessageAction.UPDATE) {
      updateThreadChatById(threadId, chatMessage.id, data);
      updateChatMessage(threadId, chatMessage);
      return;
    }
  };

  const handleThreadMessage = ({
    action,
    data,
    threadId,
  }: {
    action: MessageAction;
    data: StructThread;
    threadId: StructThread["id"];
  }) => {
    if (action === MessageAction.DELETE) {
      // The following event ensures interim state is cleared when a thread is removed from the feed but the thread is still open.
      events.emit(REMOVE_FROM_FEED_KEEP_THREAD_OPEN, {
        thread: null,
      });
      deleteThreads([threadId]);
      return;
    }

    if (action === MessageAction.UPDATE) {
      const incomingThread = data as StructThreadResponse;
      if (incomingThread.bits?.deleted || incomingThread.bits?.hidden) {
        // The following event ensures interim state is cleared when a thread is removed from the feed but the thread is still open.
        events.emit(REMOVE_FROM_FEED_KEEP_THREAD_OPEN, {
          thread: null,
        });
        deleteThreads([threadId]);
        return;
      }

      const threadsById = getThreadsById();
      const existingThread = threadsById[threadId];
      const isOpenThread = globalStore.getState().openThreadsById[threadId];
      if (!existingThread && !isOpenThread) {
        return;
      }

      const customizer = (
        objValue: StructThreadResponse,
        srcValue: StructThreadResponse,
      ) => {
        if (isArray(objValue)) {
          return srcValue;
        }
      };

      const thread = mergeWith(
        {},
        existingThread || isOpenThread,
        incomingThread,
        customizer,
      );
      const transformedThread: StructThread = joinThreadData({
        thread,
        organisation,
      });
      if (existingThread) {
        setThreadById(transformedThread);
      } else if (isOpenThread) {
        setOpenThreadById(threadId, transformedThread);
      }
    }
  };

  const handleReactionMessage = ({
    action,
    data,
    threadId,
  }: {
    action: MessageAction;
    data: any;
    threadId: StructThread["id"];
  }) => {
    const { chat_id }: { chat_id: StructChat["id"] } = data;

    if (action === MessageAction.INSERT) {
      setChatReaction(threadId, chat_id, data);
      return;
    }

    if (action === MessageAction.DELETE) {
      deleteChatReaction(threadId, chat_id, data);
      return;
    }
  };

  const handlePresenceMessage = ({
    status,
    user_ids,
  }: {
    status: PresenceStatus;
    user_ids: StructUser["id"][];
  }) => {
    if (status === PresenceStatus.ACTIVE) {
      user_ids.forEach((user_id: StructUser["id"]) => {
        addOnlineUserId(user_id);
      });
      return;
    }

    if (status === PresenceStatus.AWAY) {
      user_ids.forEach((user_id: StructUser["id"]) => {
        removeOnlineUserId(user_id);
        return;
      });
    }
  };

  const clearPingTimeout = () => {
    if (!pingTimeoutRef.current) {
      return;
    }

    clearTimeout(pingTimeoutRef.current);
  };

  const updateLastTimestamp = () => {
    const now = Date.now();
    if (
      lastPingTimestamp.current &&
      now - lastPingTimestamp.current > REFRESH_RECONNECTION_INTERVAL
    ) {
      setShouldResync(true);
      trackEvent("no_ping_received", {
        deviceId,
        lastPingTimestamp: lastPingTimestamp.current,
      });
    }
    lastPingTimestamp.current = Date.now();
  };

  const handleVersionMismatch = (version: string) => {
    console.error(
      "Received PONG with different version. Attempting to reconnect. ",
      deviceId,
    );
    setShouldResync(true);
    trackEvent("version_mismatch", {
      deviceId,
      version,
      client_version: APP_VERSION,
    });
  };

  const handleDesktopVersionMismatch = (version: string) => {
    console.error("Received PONG with different desktop version ", deviceId);
    setShouldUpgrade(true);
    trackEvent("desktop_version_mismatch", {
      deviceId,
      version,
      desktop_version: DESKTOP_APP_VERSION,
      client_version: APP_VERSION,
    });
  };

  const handlePingMessage = ({
    version,
    desktop_version,
  }: {
    version: string;
    desktop_version: string;
  }) => {
    events.emit(WS_PING_RECEIVED, {});

    if (globalStore.getState().shouldResync) {
      setShouldResync(false);
    }
    if (globalStore.getState().shouldUpgrade) {
      setShouldUpgrade(false);
    }

    if (version && APP_VERSION && version !== APP_VERSION) {
      handleVersionMismatch(version);
      return;
    }

    if (
      DESKTOP_APP_VERSION &&
      desktop_version &&
      DESKTOP_APP_VERSION !== desktop_version
    ) {
      handleDesktopVersionMismatch(desktop_version);
      return;
    }
    updateLastTimestamp();
  };

  const handleMessage = useCallback((event: MessageEvent) => {
    const {
      path,
      data,
      error,
      table,
      action,
      thread_id: threadId,
    } = JSON.parse(event.data);

    if (path === RealtimeMessagePath.PING) {
      handlePingMessage(data);
      return;
    }

    if (path === RealtimeMessagePath.SERVER_FEED) {
      handleServerFeedMessage({ data, action, threadId });
      return;
    }

    if (path === RealtimeMessagePath.PRESENCE) {
      handlePresenceMessage(data);
      return;
    }

    if (path === RealtimeMessagePath.TYPING) {
      handleTypingMessage(data, threadId);
      return;
    }

    if (path === RealtimeMessagePath.SERVER_UPDATE) {
      handleServerUpdateMessage({ type: table, action, data, threadId });
      return;
    }
  }, []);

  const handleError = (error: Event) => {
    console.error("Realtime Connection Error:", error, " ", deviceId);
    setTimeout(() => {
      initializeServerSentEvents();
    }, 5000);
  };

  const notifyReconnectionToApp = () => {
    if (!hasPriorConnectionEstablished.current) return;
    events.emit(REALTIME_RECONNECTION_TRIGGERED, {});
  };

  const initializeServerSentEvents = () => {
    const isOnline = typeof window !== undefined && window.navigator.onLine;
    if (!isOnline) {
      return;
    }

    if (sseInstanceRef.current) {
      console.log("Closing existing Realtime connection... ", deviceId);
      sseInstanceRef.current?.close();
      sseInstanceRef.current = null;
      setIsRealtimeConnectionActive(false);
    }

    console.log("Initializing Realtime connection... ", deviceId);

    const url = `${STRUCT_API_URL}/${STRUCT_API_VERSION}/events/${deviceId}`;

    try {
      const connection = new EventSource(url, { withCredentials: true });

      connection.onopen = handleOpen;
      connection.onmessage = handleMessage;
      connection.onerror = handleError;
      sseInstanceRef.current = connection;

    } catch (error) {
      console.error(error, deviceId);
      setIsRealtimeConnectionActive(false);
    }
  };

  return {
    initializeRealtimeConnection: initializeServerSentEvents,
  };
};

export default useRealtimeEvents;
