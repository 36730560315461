import { useAppStore } from "@/app/store";
import React from "react";
import ReadOnlyDialog from "./ReadOnlyDialog";
import EditableDialog from "./EditableDialog";

interface OrgInfoDialogProps {
  onClose: () => void;
}

const OrgInfoDialog = ({ onClose }: OrgInfoDialogProps) => {
  const session = useAppStore((state) => state.session);

  if (session?.isOwner || session?.isAdmin) {
    return <EditableDialog onClose={onClose} />;
  }

  return <ReadOnlyDialog onClose={onClose} />;
};

export default OrgInfoDialog;
