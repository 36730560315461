import { STRUCT_API_VERSION } from "@/app/constants";
import { structAPIFetchClient } from "@/app/services/client";
import { StructImage } from "@/app/types/Image.type";

const UPLOAD_ENDPOINT = `/${STRUCT_API_VERSION}/upload`;

export const uploadImageAPI = async (file: File): Promise<StructImage> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await structAPIFetchClient(UPLOAD_ENDPOINT, {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  const { data } = await response.json();
  return data;
};
