import { IconType } from "@/app/components/icons/icon.type";

const InsertThreadLinkIcon = ({ style, className }: IconType) => (
  <svg
    style={style}
    className={className}
    width="46"
    height="30"
    viewBox="0 0 46 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="9.23535"
      y="16.6667"
      width="35.7647"
      height="11.1111"
      rx="2"
      stroke="var(--purple-8)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4947 22.2222L0.293945 17.1788V12.2222L21.5292 12.8417L14.4947 22.2222Z"
      fill="var(--purple-2)"
    />
    <path
      d="M10.9116 17.7778C15.5411 17.7778 19.294 14.0468 19.294 9.44442C19.294 4.84204 15.5411 1.11108 10.9116 1.11108C6.2822 1.11108 2.5293 4.84204 2.5293 9.44442C2.5293 14.0468 6.2822 17.7778 10.9116 17.7778Z"
      stroke="var(--purple-9)"
      strokeMiterlimit="10"
    />
    <path
      d="M10.9118 13.3333C13.0722 13.3333 14.8235 11.5922 14.8235 9.44443C14.8235 7.29666 13.0722 5.55554 10.9118 5.55554C8.75136 5.55554 7 7.29666 7 9.44443C7 11.5922 8.75136 13.3333 10.9118 13.3333Z"
      stroke="var(--purple-9)"
      strokeMiterlimit="10"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6041 20L11.4707 16.1427L15.6703 11.1889L21.5295 10L17.6041 20Z"
      fill="var(--purple-2)"
    />
    <path
      d="M19.2938 10.1928C18.9455 13.1035 16.1471 14.9343 15.0324 11.365C14.8117 10.6537 14.8233 8.88885 14.8233 8.88885"
      stroke="var(--purple-9)"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.8234 17.0816C13.77 17.5368 12.5966 17.7777 11.3832 17.7777C6.48955 17.7777 2.5293 13.8018 2.5293 8.88885"
      stroke="var(--purple-9)"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M31.2346 13.6869C31.4298 13.8822 31.7464 13.8822 31.9417 13.6869L35.1237 10.5049C35.3189 10.3097 35.3189 9.99308 35.1237 9.79781C34.9284 9.60255 34.6118 9.60255 34.4165 9.79781L31.5881 12.6262L28.7597 9.79781C28.5644 9.60255 28.2478 9.60255 28.0526 9.79781C27.8573 9.99308 27.8573 10.3097 28.0526 10.5049L31.2346 13.6869ZM23.7121 5.60775L23.3596 5.25316L23.7121 5.60775ZM31.0881 8.44446V13.3333H32.0881V8.44446H31.0881ZM21.8818 8.13238L24.0646 5.96234L23.3596 5.25316L21.1768 7.4232L21.8818 8.13238ZM26.5322 4.94446H27.5881V3.94446H26.5322V4.94446ZM24.0646 5.96234C24.7204 5.31039 25.6075 4.94446 26.5322 4.94446V3.94446C25.3433 3.94446 24.2027 4.41495 23.3596 5.25316L24.0646 5.96234ZM32.0881 8.44446C32.0881 5.95918 30.0734 3.94446 27.5881 3.94446V4.94446C29.5211 4.94446 31.0881 6.51146 31.0881 8.44446H32.0881Z"
      fill="var(--purple-8)"
    />
    <rect
      x="37.1768"
      y="24.4445"
      width="5.58824"
      height="1.11111"
      rx="0.555556"
      fill="var(--purple-7)"
    />
  </svg>
);
export default InsertThreadLinkIcon;
