import { useCommandState } from "cmdk";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import CommandMenuItem from "@/app/components/CommandMenu/CommandMenuItem";

const NoResultsItem = ({
  onSelect,
}: {
  onSelect: (search: string) => void;
}) => {
  const { search } = useCommandState((state) => state);

  if (!search) {
    return null;
  }

  return (
    <CommandMenuItem
      value={`Search for "${search}" in threads...`}
      onSelect={() => onSelect(search)}
      icon={MagnifyingGlassIcon}
    />
  );
};

export default NoResultsItem;
