import React, { useEffect, useRef } from "react";
import { sendPresenceAPI } from "../services/misc";
import { useAppStore } from "../store";
const PRESENCE_INTERVAL = 60 * 3 * 1000; // 3 minutes

export const usePresence = () => {
  const presenceIntervalRef = useRef<number | null>(null);
  const isConnectionActive = useAppStore(
    (state) => state.isRealtimeConnectionActive,
  );

  useEffect(() => {
    return () => {
      if (presenceIntervalRef.current !== null) {
        clearInterval(presenceIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (presenceIntervalRef.current) {
      window.clearInterval(presenceIntervalRef.current);
      presenceIntervalRef.current = null;
    }

    const sendPresence = () => {
      if (!isConnectionActive) {
        return;
      }

      sendPresenceAPI();
    };

    sendPresence();

    presenceIntervalRef.current = window.setInterval(
      () => sendPresence(),
      PRESENCE_INTERVAL,
    );

    return () => {
      if (presenceIntervalRef.current) {
        window.clearInterval(presenceIntervalRef.current);
        presenceIntervalRef.current = null;
      }
    };
  }, [isConnectionActive]);
};
