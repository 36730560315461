import { StructOrganisation } from "@/app/types/Organisation.type";
import { StructUser } from "@/app/types/Thread.type";
import { STRUCT_API_VERSION } from "@/app/constants";
import { structAPIFetchClient } from "@/app/services/client";
import { SessionResponse } from "@/app/types/session";

const WHOAMI_ENDPOINT = `/${STRUCT_API_VERSION}/whoami`;

type WhoamiResponseType = {
  email: string;
  org_id: string;
  user: StructUser;
  orgs: Partial<StructOrganisation>[];
};

export const whoamiAPI = async (): Promise<WhoamiResponseType | null> => {
  let response = await structAPIFetchClient(WHOAMI_ENDPOINT, {
    method: "GET",
  });

  if (response.status === 401) {
    return null;
  } else if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  // @ts-ignore
  let data: any = await response.json();
  return data as SessionResponse;
};
