import { useState } from "react";
import { Avatar, Flex, Text } from "@radix-ui/themes";

import { ProfileDetailsDialog } from "../profile/ProfilePage/ProfileDetailsDialog";
import styles from "./UserBlock.module.scss";
import { useAppStore } from "@/app/store";

interface UserBlockProps {
  avatarSrc: string;
  fallback: string;
  alt: string;
  email: string;
}

const UserBlock = ({ avatarSrc, fallback, alt, email }: UserBlockProps) => {
  const [isProfileDialogOpen, setIsProfileDialogOpen] =
    useState<boolean>(false);

  const user = useAppStore((state) => state.session?.user);

  return (
    <>
      <Flex
        py="1"
        px="3"
        align="center"
        className={styles.userBlock}
        onClick={() => setIsProfileDialogOpen(true)}
      >
        <Avatar
          size="1"
          src={avatarSrc}
          fallback={fallback}
          alt={alt}
          style={{ marginRight: "8px" }}
        />
        <Text
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          size="2"
        >
          {email}
        </Text>
      </Flex>
      {isProfileDialogOpen && (
        <ProfileDetailsDialog
          isOpen={isProfileDialogOpen}
          onClose={() => setIsProfileDialogOpen(false)}
          user={user}
        />
      )}
    </>
  );
};

export default UserBlock;
